import Icon from "../Icon/Icon";
import { icon, languageCode } from "../../data/constants";
import { globalTexts } from "../../utils/internationalization";

const changeLanguage = ({code}) => {
  localStorage.setItem('languageCode', code);
  window.location.reload();
}

const PortalAppBar = ({backgroundClass = 'background-primary'}) => {
  return (
    <>
      <div className={`fixed width-100 ${backgroundClass} flex justify-between align-center`} style={{height: 48}}>
        <div className='flex gap-xs'>
        </div>

        <div className='flex align-center gap-xs padding-left-right-md'>
          <div className='text bold size-md'>{globalTexts.language}</div>
          {Object.keys(languageCode).map(code => (
            <div
              key={code}
              className={`${languageCode[localStorage.getItem('languageCode')] === code ? 'background-secondary color-grayscale-0' : 'background-grayscale-0  hover-grayscale-2'} padding-xs rounded-xxs pointer`}
              onClick={() => changeLanguage({code})}
            ><div className='text size-sm'>{code.toUpperCase()}</div></div>
          ))}
        </div>
      </div>

      <div className='padding-top-xxl'></div>
    </>
  )
}

export default PortalAppBar;
