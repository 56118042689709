import { inter } from "../../../utils/internationalization";


/* Portal Intro Texts */
const portalIntroTexts = {
  yourIdealRestaurantKitchenStartsHere: {
    en: 'Your ideal restaurant kitchen starts here',
    es: 'Tu cocina de restaurante perfecta empieza aquí',
    fr: 'Votre cuisine de restaurant idéale commence ici',
  },
  weBringYourKitchenVisionToLife: {
    en: 'We bring your vision to life with expert design, consultation, and installation.',
    es: 'Llevamos tu visión a la realidad con diseño experto, asesoría e instalación.',
    fr: 'On réalise votre vision avec un design expert, de consultation et d\'installation.',
  },
  over20PlusYearsOfExpertiseAcrossCalifornia: {
    en: '20+ Years of expertise across California',
    es: '20+ años de experiencia en California',
    fr: '20+ ans d\'expertise à travers Californie',
  },
  whetherYoureOpeningANewRestaurantOrUpgradingAnExistingOne: {
    en: 'Whether you\'re opening a new restaurant or upgrading an existing one, we provide expert consultation, custom designs, and full installation services',
    es: 'Ya sea que estés abriendo un nuevo restaurante o mejorando uno existente, te damos asesoría experta, diseño personalizado y servicio de instalación completo',
    fr: 'Que vous ouvriez un nouveau restaurant ou amélioriez un existant, on vous propose une consultation experte, un design personnalisé et une installation complète',
  },
  weCreateEfficientFunctionalKitchensTailoredToYourNeeds: {
    en: 'We create efficient, functional kitchens tailored to your needs.',
    es: 'Creamos cocinas eficientes y funcionales adaptadas a tus necesidades.',
    fr: 'On crée des cuisines efficaces et fonctionnelles adaptées à vos besoins.',
  },
  restaurantOwnersTrustTheCustomKitchenDesignsExpertInstallationsAndReliabilityWeDeliver: {
    en: 'Restaurant owners trust the custom kitchen designs, expert installations, and reliability we deliver.',
    es: 'Los propietarios de restaurantes confían en los diseños de cocinas personalizados, instalaciones expertas y fiabilidad que ofrecemos.',
    fr: 'Les propriétaires de restaurants font confiance aux designs de cuisines personnalisés, installations experts et fiabilité qu\'on vous propose.',
  },
  ensureSafetyAndEfficiencyWithAAndLHoods: {
    en: 'Ensure Safety & Efficiency with A&L Hoods',
    es: 'Garantiza la seguridad y eficiencia con A&L Hoods',
    fr: 'Assurez la sécurité et l\'efficacité avec A&L Hoods',
  },
  weHandleEverythingFromDetailedDesignsToTheHighestQualityMaterials: {
    en: 'We handle everything—from detailed designs to the highest-quality materials, ensuring safety, Fire Department approvals, and code compliance.',
    es: 'Manejamos todo—desde diseños detallados hasta los materiales de la más alta calidad, asegurando seguridad, aprobaciones de Bomberos y cumplimiento de normas.',
    fr: 'On gère tout—des designs détaillés aux matériaux de la plus haute qualité, garantissant la sécurité, les approbations du service de la protection civile et la conformité aux normes.',
  },
}


/* Portal Testimonials Texts */
const portalTestimonialsTexts = {
  exhibit1AraisRestaurantLovedUs: {
    en: 'Exhibit 1: Arais Restaurant loved us, and confrimed we are 30% more affordable',
    es: 'Prueba 1: Arais Restaurant nos amó, y nos confirmó que somos 30% más económicos',
    fr: 'Arais Restaurant nous aime, et nous a confirmé que nous sommes 30% plus abordables',
  },
  fromKitchenDesignToHoodInstallationWeBuiltButcherTheMarketNewRestaurant: {
    en: 'From kitchen design to hood installation - we built Butcher The Market\'s new restaurant in San Juan Capistrano',
    es: 'Desde el diseño de la cocina hasta la instalación de la campana - construimos el nuevo restaurante de Butcher The Market en San Juan Capistrano',
    fr: 'De la conception de la cuisine à l\'installation de la hotte - on a construit le nouveau restaurant de Butcher The Market à San Juan Capistrano',
  },
}


/* Portal Contact Flow Texts */
const contactFlowTexts = {
  howCanWeHelp: {
    en: 'How can we help?',
    es: '¿Cómo podemos ayudarte?',
    fr: 'Comment est-ce que on peut vous aider?',
  },
  getInTouchNow: {
    en: 'Get in touch now',
    es: 'Contáctanos ahora',
    fr: 'Contactez-nous maintenant',
  },
  letUsHelpYou: {
    en: 'Let us help you',
    es: 'Deja que te ayudemos',
    fr: 'Laissons-nous vous aider',
  },
  whatCanWeHelpYouWith: {
    en: 'What can we help you with?',
    es: '¿Con qué podemos ayudarte?',
    fr: 'On peut vous aider',
  },
  getInTouchToBecomeAnotherSatisfiedCustomer: {
    en: 'Get in touch to become another satisfied customer',
    es: 'Contáctanos para ser otro cliente satisfecho',
    fr: 'Contactez-nous pour devenir un autre client satisfait',
  },
}


/* Portal Product Or Service Texts */
const portalProductOrServiceTexts = {
  kitchenDesignConsultation: {
    en: 'Kitchen Design Consultation',
    es: 'Consultoría en Diseño de Cocina',
    fr: 'Consultation en Design de Cuisine',
  },
  customHoodInstallation: {
    en: 'Custom Hood Installation',
    es: 'Instalación de Campana Personalizada',
    fr: 'Installation de Hotte Personnalisée',
  },
  kitchenExhaustFanSystem: {
    en: 'Kitchen Exhaust Fan System',
    es: 'Sistema de Extracción de Aire de la Cocina',
    fr: 'Système d\'Extraction d\'air de la Cuisine',
  },
  maintenanceRepairAndExpertCleaning: {
    en: 'Maintenance, Repair & Expert Cleaning',
    es: 'Mantenimiento, Reparación y Limpieza Experta',
    fr: 'Maintenance, Réparation et Nettoyage Expert',
  },
  fireSprinklerSystemsInstallation: {
    en: 'Fire Sprinkler Systems Installation',
    es: 'Instalación de Sistemas Contra Incendio',
    fr: 'Installation de Systèmes de Protection contre les Incendies',
  },
  whatWeOffer: {
    en: 'What we offer',
    es: 'Lo que ofrecemos',
    fr: 'Ce qu\'on propose',
  },
  licensedKitchenDesign: {
    en: 'Licensed Kitchen Design',
    es: 'Diseño de cocina licenciado',
    fr: 'Design de cuisine licencié',
  },
  customKitchenLayouts: {
    en: 'Custom Kitchen Layouts',
    es: 'Diseño de cocina personalizado',
    fr: 'Design de cuisine personnalisé',
  },
  tailoredSolutions: {
    en: 'Tailored Solutions',
    es: 'Soluciones personalizadas',
    fr: 'Solutions personnalisées',
  },
  stainlessSteelExpertise: {
    en: 'Stainless Steel Expertise',
    es: 'Expertise en acero inoxidable',
    fr: 'Expertise en acier inoxydable',
  },
  weCreateThePerfectKitchenLayoutForYourRestaurantsNeeds: {
    en: 'We create the perfect kitchen layout for your restaurant\'s needs',
    es: 'Creamos la cocina perfecta para tus necesidades',
    fr: 'On crée la cuisine idéale pour vos besoins',
  },
  weDesignKitchensWithMaxEfficiencyWorkflowAndFunctionality: {
    en: 'We design kitchens with max efficiency, workflow, and functionality.',
    es: 'Creamos cocinas con máxima eficiencia, flujo de trabajo y funcionalidad.',
    fr: 'On crée des cuisines avec une grande efficacité, un flux de travail et une fonctionnalité.',
  },
  asAFullLicensedAndInsuredCompanyWeBringBothExpertiseAndLegalComplianceToEveryProject: {
    en: 'As a fully licensed and insured company, we bring both expertise and legal compliance to every project.',
    es: 'Como empresa licenciada y asegurada, nos cumplimos con todas las normativas legales y ofrecemos un servicio de calidad.',
    fr: 'Comme entreprise licenciée et assurée, on respecte toutes les réglementations légales et offre un service de qualité.',
  },
  ourGoalIsToCreateAKitchenThatMeetsYourNeedsAndSupportsTheGrowthOfYourBusinessForYearsToCome: {
    en: 'Our goal is to create a kitchen that meets your needs and supports the growth of your business for years to come.',
    es: 'Nuestro objetivo es crear una cocina que cumpla con tus necesidades y apoye el crecimiento de tu negocio a lo largo de los años.',
    fr: 'Notre objectif est de créer une cuisine qui répond à vos besoins et qui soutient le développement de votre entreprise pendant de nombreuses années.',
  },
}

const allTexts = {
  ...portalIntroTexts,
  ...contactFlowTexts,
  ...portalTestimonialsTexts,
  ...portalProductOrServiceTexts,
}

const helperAAndLHoodsTexts = allTexts;

const aAndLHoodsTexts = inter(allTexts);

export { aAndLHoodsTexts, helperAAndLHoodsTexts };