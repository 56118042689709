import { useEffect, useState } from "react";
import isWeb from "../../utils/responsive";
import PortalFrameableImage from "./PortalFrameableImage";

const SquareImageListAndContent = ({images, contentPercentage = 50, squareDimensions, rotateEvery = 6600, gapBetweenImagesClass = 'gap-md', children}) => {
  const [imagesToShow, setImagesToShow] = useState(images);

  useEffect(() => {
    setTimeout(() => {
      // Rotate items in array
      const rotatedImages = [...imagesToShow.slice(1), imagesToShow[0]];
      setImagesToShow(rotatedImages);
    }, rotateEvery);
  }, [imagesToShow]);

  if(isWeb()) {
    return (
      <div className='flex center'>
        <div style={{width: `${contentPercentage}%`}}>
          {children}
        </div>

        <div style={{width: `${100 - contentPercentage}%`, overflow: 'hidden'}}>
          {/* <div className='position-absolute' style={{width: '100%', height: '100%', zIndex: 1, background: 'linear-gradient(to left, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 15%)'}}></div> */}

          <div className={`flex ${gapBetweenImagesClass}`} style={{width: '2160px'}}>
            {imagesToShow.map((image, index) => (
              <PortalFrameableImage src={image.src} squareDimensions={squareDimensions} imageDimensions={image.dimensions} top={image.top} left={image.left} key={index} />
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex column center'>
      <PortalFrameableImage src={imagesToShow[0].src} squareDimensions={squareDimensions} imageDimensions={imagesToShow[0].dimensions} top={imagesToShow[0].top} left={imagesToShow[0].left} />
      {children}
    </div>
  )
}

export default SquareImageListAndContent;