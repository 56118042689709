import AppBar from "../AppBar/AppBar";
import dataLayer from "../../data/dataLayer";
import entityMetadata from "../../entities/entityMetadata";
import ThumbEditProperty from "../Input/ThumbEditProperty";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { globalTexts, inter } from "../../utils/internationalization";
import IconAndPropertyEditable from "../Global/IconAndPropertyEditable";

const saveProperty = ({partner, setEditingPropertyName}) => {
  dataLayer.partner.update({partner});
  setEditingPropertyName(null);
};

const PartnerDetailedInfo = () => {
  const {partnerId} = useParams();
  const [partner, setPartner] = useState({});
  const [editingPropertyName, setEditingPropertyName] = useState(null);

  useEffect(() => {
    dataLayer.partner.onGet({partnerId, callback: ({document}) => setPartner(document)});
  }, []);

  return (
    <>
      <AppBar backUrl={`/partner/${partnerId}`} />

      <div className='padding-lg'>
        <div className='text center bold size-xxl'>{partner.name}</div>

        <div className='text center size-lg padding-xxs padding-left-right-lg'>{globalTexts.clickTapToChangeOrAddInfo}</div>
      </div>

      <div className='flex column gap-lg padding-lg'>
        <IconAndPropertyEditable size='xl' propertyName='name' value={partner.name} onClick={() => setEditingPropertyName('name')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable size='xl' propertyName='preferredLanguage' value={partner.preferredLanguage} onClick={() => setEditingPropertyName('preferredLanguage')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='portalUrl' value={partner.portalUrl} onClick={() => setEditingPropertyName('portalUrl')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='phone' value={partner.phone} onClick={() => setEditingPropertyName('phone')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='email' value={partner.email} onClick={() => setEditingPropertyName('email')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='website' value={partner.website} onClick={() => setEditingPropertyName('website')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='address' value={partner.address} onClick={() => setEditingPropertyName('address')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='imageUrl' value={partner.imageUrl} onClick={() => setEditingPropertyName('imageUrl')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='instagram' value={partner.instagram} onClick={() => setEditingPropertyName('instagram')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='twitter' value={partner.twitter} onClick={() => setEditingPropertyName('twitter')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='youtube' value={partner.youtube} onClick={() => setEditingPropertyName('youtube')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='linkedin' value={partner.linkedin} onClick={() => setEditingPropertyName('linkedin')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='facebook' value={partner.facebook} onClick={() => setEditingPropertyName('facebook')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='notes' value={partner.notes} onClick={() => setEditingPropertyName('notes')} entityMetadata={entityMetadata.partner} />
      </div>

      <ThumbEditProperty propertyName={editingPropertyName} entity={partner} setEntity={setPartner} onFinish={() => saveProperty({partner, setEditingPropertyName})} finishButtonText={globalTexts.save} entityMetadata={entityMetadata.partner} />
    </>
  );
};

export default PartnerDetailedInfo;
