import isWeb from "../../utils/responsive";

const BackgroundImageAndContent = ({ image, width = '100%', height, left, web, mobile, additionalStyles }) => {
  if(!isWeb()) {
  return (
    <div style={{ position: 'relative', overflow: 'hidden', ...additionalStyles }}>
      <div style={{ position: 'absolute', width, height, overflow: 'hidden', zIndex: -1 }}>
        <img className='normal-to-brightness-33' src={image} alt='A&L Hoods image' style={{position: 'relative', top: 0, left, xyzwidth: '100%', height: '100%'}} />
      </div>

      <div className='transparent-to-opaque'>
        {mobile}
      </div>
    </div>
  )}

  return (
    <div className='flex'>
      <div className='flex center' style={{width: '60%'}}>
        <div style={{maxWidth: '600px'}}>
          {web}
        </div>
      </div>

      <div style={{ width: '40%', height, overflow: 'hidden', zIndex: -1 }}>
        <img className='normal-to-bright-loop' src={image} alt='A&L Hoods image' style={{position: 'relative', top: 0, left, xyzwidth: '100%', height: '100%'}} />
      </div>
    </div>
  );
}

export default BackgroundImageAndContent;