import ItemCard from "../Global/ItemCard";
import { useNavigate } from "react-router-dom";
import { globalTexts } from "../../utils/internationalization";

const ReferralProgramItem = ({referralProgram}) => {
  const navigate = useNavigate();

  return (
    <>
      <ItemCard width={184} onClick={() => navigate(`/referral-program/${referralProgram.id}`)}>
        <div className='padding-sm flex column gap-sm'>
          <div>
            <div className='text bold size-md color-grayscale-6'>{globalTexts.targetAudience}</div>
            <div className='text bold size-lg'>{referralProgram.targetAudience}</div>
          </div>
          <div className='text size-sm preformatted'>{referralProgram.description}</div>
        </div>
      </ItemCard>
    </>
  );
};

export default ReferralProgramItem;