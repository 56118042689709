import Icon from '../Icon/Icon';
import AppBar from '../AppBar/AppBar';
import Footer from '../Footer/Footer';
import isWeb from '../../utils/responsive';
import ThumbInput from '../Input/ThumbInput';
import templates from '../../data/templates';
import dataLayer from '../../data/dataLayer';
import AddItemCard from '../Global/AddItemCard';
import ThumbStep from '../Interaction/ThumbStep';
import Clientv2Item from '../Client/Clientv2Item';
import ThingsFlow from '../Interaction/ThingsFlow';
import entityMetadata from '../../entities/entityMetadata';
import ReferralProgramItem from '../ReferralProgram/ReferralProgramItem';
import { useEffect, useState } from 'react';
import { clientStage, icon } from '../../data/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { globalTexts, inter } from '../../utils/internationalization';

const getHighestIndex = ({referralPrograms}) => {
  return referralPrograms.reduce((highestIndex, referralProgram) => {
    return referralProgram.index > highestIndex ? referralProgram.index : highestIndex;
  }, 0);
};

const saveReferralProgram = async ({referralProgram, navigate}) => {
  const newReferralProgram = await dataLayer.referralProgram.update({referralProgram});
  navigate(`/referral-program/${newReferralProgram.id}`);
};

const saveClientv2 = async ({clientv2, navigate}) => {
  const newClientv2 = await dataLayer.clientv2.update({clientv2});
  navigate(`/clientv2/${newClientv2.partnerId}/${newClientv2.id}`);
};

const Partner = () => {
  const navigate = useNavigate();
  const {partnerId} = useParams();
  const [partner, setPartner] = useState({});
  const [clientsv2, setClientsv2] = useState([]);
  const [referralPrograms, setReferralPrograms] = useState([]);
  const [isAddingClientv2, setIsAddingClientv2] = useState(false);
  const [clientv2, setClientv2] = useState({...templates.clientv2(), partnerId});
  const [referralProgram, setReferralProgram] = useState({...templates.referralProgram, partnerId});

  useEffect(() => {
    dataLayer.partner.onGet({partnerId, callback: ({document}) => setPartner(document)});
    dataLayer.clientv2.onListChange({partnerId, callback: ({documents}) => setClientsv2(documents)});
    dataLayer.referralProgram.onListChange({partnerId, callback: ({documents}) => {
      setReferralPrograms(documents);
      setReferralProgram({...referralProgram, index: getHighestIndex({referralPrograms: documents}) + 1});
    }});
  }, []);

  return (
    <>
      <AppBar backUrl={`/`} />

      <div className='padding-lg'>
        <div className="text bold size-xxl">{partner.name}</div>

        <div className='flex gap-xxxs align-center'>
          <div className="text size-md uppercase text-only-action" onClick={() => navigate(`/partner-detailed-info/${partnerId}`)}>{texts.showPartnerDetailedInfo}</div>
          <Icon name={icon.arrowRight} dimensions="18px" stroke="var(--palette-action)" />
        </div>
      </div>

      <div className={`text size-xxl padding-left-right-lg padding-top-md ${isWeb() ? '' : 'center'}`}>{referralPrograms.length} {globalTexts.referralPrograms}</div>

      <div className='flex wrap gap-lg padding-left-right-lg padding-top-md'>
        {referralPrograms.sort((a, b) => a.index - b.index).map((referralProgram, index) => (
          <ReferralProgramItem referralProgram={referralProgram} key={index} />
        ))}
        <AddItemCard onClick={() => saveReferralProgram({referralProgram, navigate})} />
      </div>

      <div className='padding-xxl' />

      <div className={`text size-xxl padding-left-right-lg ${isWeb() ? '' : 'center'}`}>{clientsv2.filter(client => client.stage === clientStage.lead).length} {globalTexts.leads}</div>

      <div className='flex wrap gap-lg padding-left-right-lg padding-top-md'>
        {clientsv2.filter(clientv2 => clientv2.stage === clientStage.lead).map((clientv2, index) => (
          <Clientv2Item clientv2={clientv2} key={index} />
        ))}
        <AddItemCard onClick={() => {setClientv2({...clientv2, stage: clientStage.lead}); setIsAddingClientv2(true)}} />
      </div>

      <div className='padding-xxl' />

      <div className={`text size-xxl padding-left-right-lg ${isWeb() ? '' : 'center'}`}>{clientsv2.filter(client => client.stage === clientStage.customer).length} {globalTexts.clients}</div>

      <div className='flex wrap gap-lg padding-left-right-lg padding-top-md'>
        {clientsv2.filter(clientv2 => clientv2.stage === clientStage.customer).map((clientv2, index) => (
          <Clientv2Item clientv2={clientv2} key={index} />
        ))}
        <AddItemCard onClick={() => {setClientv2({...clientv2, stage: clientStage.customer}); setIsAddingClientv2(true)}} />
      </div>

      {isAddingClientv2 && (
        <ThingsFlow
          onFinish={() => saveClientv2({clientv2, navigate})}
          finishButtonText={globalTexts.createNewClient}
        >
          <ThumbStep>
            <ThumbInput label={entityMetadata.clientv2.name.labelText} tip={entityMetadata.clientv2.name.tipText} value={clientv2.name} onChange={({target}) => setClientv2({...clientv2, name: target.value})} />
          </ThumbStep>
        </ThingsFlow>
      )}

      <Footer />
    </>
  )
}

const texts = inter({
  showPartnerDetailedInfo: {
    en: 'Show all details',
    es: 'Mostrar todos los detalles',
    fr: 'Afficher tous les détails',
  },
});

export default Partner;
