import PortalContactFlow from "./PortalContactFlow";
import { cloneElement, useState } from "react";
import { globalTexts } from "../../utils/internationalization";

const PortalProductsAndServicesV2 = ({children, colorClass, contactInfo, paddingClass = 'padding-left-right-xl'}) => {
  const [isContactFlowOpen, setIsContactFlowOpen] = useState(false);
  const [serviceIndexShowMoreInfo, setServiceIndexShowMoreInfo] = useState([]);

  return (
    <div className={`${colorClass} flex column gap-md`}>
      <div className='padding-top-bottom-lg text bold size-xl center'>{globalTexts.checkOutOurServices}</div>

      <div className={`padding-top-md flex justify-center wrap ${paddingClass}`} style={{columnGap: '6%', rowGap: '32px'}}>
        {children.map((child, index) => (
          <>
            <div style={{width: !serviceIndexShowMoreInfo.includes(index) ? '47%' : '100%'}} onClick={() => setServiceIndexShowMoreInfo([...serviceIndexShowMoreInfo, index])}>
              {cloneElement(child, {isExpanded: serviceIndexShowMoreInfo.includes(index), paddingClass, setIsContactFlowOpen})}
            </div>
          </>
        ))}
      </div>

      <PortalContactFlow contactInfo={contactInfo} shouldRender={isContactFlowOpen} />
    </div>
  )
}

export default PortalProductsAndServicesV2;