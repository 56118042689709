import PortalButton from "./PortalButton";
import ContactFlowStartButton from "./PortalContactFlowStartButton";
import { globalTexts } from "../../utils/internationalization";

const PortalProductOrService = ({image, title, children, isExpanded, colorClass, setIsContactFlowOpen}) => {
  return (
    <>
      <div className={`flex column center gap-md ${colorClass}`}>
        <img src={image} alt={title} style={{width: '100%'}} />

        <div className={`text bold ${isExpanded ? 'size-xl' : 'size-lg'} center`}>{title}</div>

        {!isExpanded ? (
          <PortalButton text={globalTexts.moreInfo} />
        ) : (
          <>
            {children}
            <ContactFlowStartButton callToActionText={globalTexts.contactUsToAskAboutThisService} contactFlowElementSetter={setIsContactFlowOpen} backgroundClass='background-secondary' colorClass='color-secondary' />
          </>
        )}
      </div>
    </>
  )
}

export default PortalProductOrService;