import Icon from "../Icon/Icon";
import IconAndDetailNonEditable from "../Global/IconAndDetailNonEditable";
import { icon } from "../../data/constants";
import { useNavigate } from "react-router-dom";
import { inter } from "../../utils/internationalization";

const PartnerItem = ({partner}) => {
  const navigate = useNavigate();

  return (
    <div className='padding-lg pointer' onClick={() => navigate(`/partner/${partner.id}`)}>
      <div className='text size-xxl bold'>{partner.name}</div>

      <div className='flex gap-xs padding-top-xs'>
        <div className='flex column gap-xxs' style={{width: '49%'}}>
          <IconAndDetailNonEditable icon={icon.whatsapp} value={partner.phone} size='md' />
          <IconAndDetailNonEditable icon={icon.email} value={partner.email} size='md' />
        </div>

        <div className='flex column wrap gap-xxs' style={{width: '49%'}}>
          <IconAndDetailNonEditable icon={icon.instagram} value={partner.instagram} size='md' />
          <IconAndDetailNonEditable icon={icon.web} value={partner.portalUrl} size='md' />
        </div>
      </div>

      <div className='flex justify-between padding-top-sm'>
        <div className='flex  align-center' style={{maxWidth: '32%'}} onClick={event => {event.stopPropagation(); navigate(`/portal/${partner.portalUrl}/${partner.id}`)}}>
          <Icon name={icon.web} dimensions='48' stroke='var(--palette-action)' />
          <div className='text center size-xxs' style={{maxWidth: '48px'}}>{texts.goToWebsite}</div>
        </div>
        <div className='flex  align-center' style={{maxWidth: '32%'}} onClick={event => {event.stopPropagation(); navigate(`/events/${partner.id}`)}}>
          <Icon name={icon.lightning} dimensions='48' stroke='var(--palette-action)' />
          <div className='text center size-xxs' style={{maxWidth: '48px'}}>{texts.motivatePartner}</div>
        </div>
        <div className='flex  align-center' style={{maxWidth: '32%'}} onClick={event => {event.stopPropagation(); navigate(`/referral/${partner.id}`)}}>
          <Icon name={icon.qrCode} dimensions='48' stroke='var(--palette-action)' />
          <div className='text center size-xxs' style={{maxWidth: '48px'}}>{texts.generateReferrals}</div>
        </div>
      </div>
    </div>
  );
};

const texts = inter({
  goToWebsite: {
    en: 'Go to web portal',
    es: 'Ir a portal web',
    fr: 'Aller portail web',
  },
  motivatePartner: {
    en: 'Motivate',
    es: 'Motivar',
    fr: 'Motiver',
  },
  generateReferrals: {
    en: 'Generate referrals',
    es: 'Generar referidos',
    fr: 'Générer parrainages',
  },
});

export default PartnerItem;