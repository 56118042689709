import brainBowl1 from '../../assets/img/brainBowl1.png';
import bgPurpleRoughShimmer3 from '../../assets/img/bgPurpleRoughShimmer3.jpg';

const Footer = () => {
  return (
    <>
      <div className='padding-xl' />

      <div className='background-grayscale-9' style={{
        position: 'relative',
      }}>
        <div
          style={{
            maxHeight: '256px',
            overflow: 'hidden',
          }}
        >
          <img
            src={bgPurpleRoughShimmer3}
            style={{
              width: '100%',
              zIndex: 1,
            }}
            alt=''
          />
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
            background: 'linear-gradient(to right, rgba(255, 66, 255, 0.25), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75))'
          }}></div>
        <div
          className='flex justify-between align-end padding-md'
          style={{
            position: 'relative',
            height: '90px',
            zIndex: 3
          }}
        >
          <div className='text size-md' style={{color: '#ffffff', filter: 'drop-shadow(0 0 4px #ffffff)'}}>Brain Flow - by Flow Kaching - 2024</div>
          <img src={brainBowl1} style={{width: '156px'}} alt='' />
        </div>
      </div>
    </>
  );
};

export default Footer;