const clientStage = {
  lead: 'lead',
  contacted: 'contacted',
  engaged: 'engaged',
  trial: 'trial',
  interested: 'interested',
  negotiating: 'negotiating',
  customer: 'customer',
  inactive: 'inactive',
};

const elementType = {
  tel: 'tel',
  text: 'text',
  date: 'date',
  email: 'email',
  emoji: 'emoji',
  number: 'number',
  select: 'select',
  textarea: 'textarea',
};

const projectStatus = {
  onHold: 'onHold',
  finished: 'finished',
  cancelled: 'cancelled',
  exploring: 'exploring',
  inProgress: 'inProgress',
  negotiating: 'negotiating',
  preparingToStart: 'preparingToStart',
};

const referralStatus = {
  new: 'new',
  processed: 'processed',
  processing: 'processing',
};

// Since this is not translated, it's possible this is not a constant but instead needs to be moved to 'texts' on the referrals page.
const referralStatusInfo = {
  [referralStatus.new]: 'The referral was generated but nothing more. Nobody else has taken any action.',
  [referralStatus.processing]: 'Messages are being sent to the person and leads.',
  [referralStatus.processed]: 'Enough time has passed that we consider this referral closed. It can still generate sales but will only re-activate if there is any action on it.',
};

const dimensionsVsSizeMap = {
  xxxs: 8,
  xxs: 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 24,
  xxl: 32,
  xxxl: 48,
}

const eventType = {
  website: 'website',
  onboarding: 'onboarding',
  clientReferrals: 'clientReferrals',
};

const eventSubtype = {
  [eventType.website]: {
    update: 'update',
  },
  [eventType.onboarding]: {
    letsBuildYourClientDatabaseInfo: 'letsBuildYourClientDatabaseInfo',
  },
  [eventType.clientReferrals]: {
    qrCodeScanned: 'qrCodeScanned',
    textForClientToShareWithOthersToGetReferralsCopied: 'textForClientToShareWithOthersToGetReferralsCopied',
  },
};

const languageCode = {
  en: 'en',
  es: 'es',
  fr: 'fr',
};

const languageCodeLabels = {
  [languageCode.en]: 'English',
  [languageCode.es]: 'Español',
  [languageCode.fr]: 'Français',
};

const icon = {
  add: 'add',
  web: 'web',
  tree: 'tree',
  user: 'user',
  star: 'star',
  find: 'find',
  gift: 'gift',
  info: 'info',
  heart: 'heart',
  image: 'image',
  notes: 'notes',
  email: 'email',
  phone: 'phone',
  circle: 'circle',
  qrCode: 'qrCode',
  delete: 'delete',
  people: 'people',
  company: 'company',
  address: 'address',
  twitter: 'twitter',
  youtube: 'youtube',
  message: 'message',
  language: 'language',
  calendar: 'calendar',
  cocktail: 'cocktail',
  linkedin: 'linkedin',
  halfstar: 'halfstar',
  facebook: 'facebook',
  location: 'location',
  whatsapp: 'whatsapp',
  telegram: 'telegram',
  lightning: 'lightning',
  instagram: 'instagram',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  fingerprint: 'fingerprint',
  moneyInvoice: 'moneyInvoice',
  calendarCheck: 'calendarCheck',
};

// Taken from https://www.prosettings.com/emoji-list/
const emojiAndDescription = [
  {emoji: '😀', description: 'grinning face'},
  {emoji: '😃', description: 'grinning face with big eyes'},
  {emoji: '😄', description: 'grinning face with smiling eyes'},
  {emoji: '😁', description: 'beaming face with smiling eyes'},
  {emoji: '😆', description: 'grinning squinting face'},
  {emoji: '😅', description: 'grinning face with sweat'},
  {emoji: '🤣', description: 'rolling on the floor laughing'},
  {emoji: '😂', description: 'face with tears of joy'},
  {emoji: '🙂', description: 'slightly smiling face'},
  {emoji: '🙃', description: 'upside-down face'},
  {emoji: '😉', description: 'winking face'},
  {emoji: '😊', description: 'smiling face with smiling eyes'},
  {emoji: '😇', description: 'smiling face with halo'},
  {emoji: '🥰', description: 'smiling face with hearts'},
  {emoji: '😍', description: 'smiling face with heart-eyes'},
  {emoji: '🤩', description: 'star-struck'},
  {emoji: '😘', description: 'face blowing a kiss'},
  {emoji: '😗', description: 'kissing face'},
  {emoji: '😚', description: 'kissing face with closed eyes'},
  {emoji: '😙', description: 'kissing face with smiling eyes'},
  {emoji: '😋', description: 'face savoring food'},
  {emoji: '😛', description: 'face with tongue'},
  {emoji: '😜', description: 'winking face with tongue'},
  {emoji: '🤪', description: 'zany face'},
  {emoji: '😝', description: 'squinting face with tongue'},
  {emoji: '🤑', description: 'money-mouth face'},
  {emoji: '🤗', description: 'hugging face'},
  {emoji: '🤭', description: 'face with hand over mouth'},
  {emoji: '🤫', description: 'shushing face'},
  {emoji: '🤔', description: 'thinking face'},
  {emoji: '🤐', description: 'zipper-mouth face'},
  {emoji: '🤨', description: 'face with raised eyebrow'},
  {emoji: '😐', description: 'neutral face'},
  {emoji: '😑', description: 'expressionless face'},
  {emoji: '😶', description: 'face without mouth'},
  {emoji: '😏', description: 'smirking face'},
  {emoji: '😒', description: 'unamused face'},
  {emoji: '🙄', description: 'face with rolling eyes'},
  {emoji: '😬', description: 'grimacing face'},
  {emoji: '🤥', description: 'lying face'},
  {emoji: '😌', description: 'relieved face'},
  {emoji: '😔', description: 'pensive face'},
  {emoji: '😪', description: 'sleepy face'},
  {emoji: '🤤', description: 'drooling face'},
  {emoji: '😴', description: 'sleeping face'},
  {emoji: '😷', description: 'face with medical mask'},
  {emoji: '🤒', description: 'face with thermometer'},
  {emoji: '🤕', description: 'face with head-bandage'},
  {emoji: '🤢', description: 'nauseated face'},
  {emoji: '🤮', description: 'face vomiting'},
  {emoji: '🤧', description: 'sneezing face'},
  {emoji: '🥵', description: 'hot face'},
  {emoji: '🥶', description: 'cold face'},
  {emoji: '🥴', description: 'woozy face'},
  {emoji: '😵', description: 'dizzy face'},
  {emoji: '🤯', description: 'exploding head'},
  {emoji: '🤠', description: 'cowboy hat face'},
  {emoji: '🥳', description: 'partying face'},
  {emoji: '😎', description: 'smiling face with sunglasses'},
  {emoji: '🤓', description: 'nerd face'},
  {emoji: '🧐', description: 'face with monocle'},
  {emoji: '😕', description: 'confused face'},
  {emoji: '😟', description: 'worried face'},
  {emoji: '🙁', description: 'slightly frowning face'},
  {emoji: '😮', description: 'face with open mouth'},
  {emoji: '😯', description: 'hushed face'},
  {emoji: '😲', description: 'astonished face'},
  {emoji: '😳', description: 'flushed face'},
  {emoji: '🥺', description: 'pleading face'},
  {emoji: '😦', description: 'frowning face with open mouth'},
  {emoji: '😧', description: 'anguished face'},
  {emoji: '😨', description: 'fearful face'},
  {emoji: '😰', description: 'anxious face with sweat'},
  {emoji: '😥', description: 'sad but relieved face'},
  {emoji: '😢', description: 'crying face'},
  {emoji: '😭', description: 'loudly crying face'},
  {emoji: '😱', description: 'face screaming in fear'},
  {emoji: '😖', description: 'confounded face'},
  {emoji: '😣', description: 'persevering face'},
  {emoji: '😞', description: 'disappointed face'},
  {emoji: '😓', description: 'downcast face with sweat'},
  {emoji: '😩', description: 'weary face'},
  {emoji: '😫', description: 'tired face'},
  {emoji: '😤', description: 'face with steam from nose'},
  {emoji: '😡', description: 'pouting face'},
  {emoji: '😠', description: 'angry face'},
  {emoji: '🤬', description: 'face with symbols on mouth'},
  {emoji: '😈', description: 'smiling face with horns'},
  {emoji: '👿', description: 'angry face with horns'},
  {emoji: '💀', description: 'skull'},
  {emoji: '💩', description: 'pile of poo'},
  {emoji: '🤡', description: 'clown face'},
  {emoji: '👹', description: 'ogre'},
  {emoji: '👺', description: 'goblin'},
  {emoji: '👻', description: 'ghost'},
  {emoji: '👽', description: 'alien'},
  {emoji: '👾', description: 'alien monster'},
  {emoji: '🤖', description: 'robot'},
];

export {
  icon,
  eventType,
  elementType,
  clientStage,
  eventSubtype,
  languageCode,
  projectStatus,
  referralStatus,
  languageCodeLabels,
  referralStatusInfo,
  dimensionsVsSizeMap,
  emojiAndDescription,
};
