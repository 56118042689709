const OverridesForPartnerCss = `

/* Palette */
:root {
  --text-primary: #ffffff;
  --text-secondary: #000000;
  --text-tertiary: #d12e18;
  --text-quaternary: #f0bc1a;
  --text-quinary: #fef773;

  --background-primary: #0a0a0a;
  --background-secondary: #ffffff;
  --background-tertiary: #f0bc1a;
  --background-quaternary: #d12e18;
  --background-quinary: #fef773;
}

/* import fonts from local */
@font-face {
  font-family: 'AkzidenzGroteskExtended';
  src: url('../assets/font/AkzidenzGrotesk-Extended.otf') format('opentype');
  src: url('../assets/font/AkzidenzGrotesk-MediumExtended.otf') format('opentype');
  src: url('../assets/font/AkzidenzGrotesk-BoldExtended.otf') format('opentype');
}


/* Globals */
body {
  font-family: 'AkzidenzGroteskExtended', sans-serif;
}


/* Interaction */
input, textarea {
  font-family: 'AkzidenzGroteskExtended', sans-serif;
}


/* Typography */
.text.title {
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: none;
}

























`;

export default OverridesForPartnerCss;
