import string from "../utils/string";
import { clientStage, eventSubtype, eventType, projectStatus, referralStatus } from "./constants";

const helperContactProperties = {
  phone: '',
  email: '',
  address: '',
  website: '',
  twitter: '',
  youtube: '',
  linkedin: '',
  facebook: '',
  instagram: '',
};


// Contact Message
const contactMessage = {
  id: string.generateAlphaNumericCode({ digits: 9 }),
  message: '',
  contactInfo: '',
  dateReviewed: '',
  isReviewed: false,
  partnerId: '',
}


// Referral Program
const referralProgram = {
  index: 0,
  description: '',
  targetAudience: '',
  whatHelperStandsToWin: '',
  whatNewClientStandsToWin: '',
  notes: '',
  partnerId: '',
}


// Event
const event = {
  type: eventType.website,
  subtype: eventSubtype[eventType.website].update,
  notes: '',
  clientId: '',
}


// Clientv2
const clientv2 = {
  name: '',
  type: '',
  notes: '',
  partnerId: '',
  referralId: null,
  preferredLanguage: '',
  stage: clientStage.lead,
  ...helperContactProperties,
  code: string.generateAlphaNumericCode({ digits: 3 }),
};


// Referral
const referral = {
  status: referralStatus.new,
  personId: '',
  clientId: '',
  partnerId: '',
  personName: '',
  personEmail: '',
  personPhone: '',
  personTwitter: '',
  personYoutube: '',
  personLinkedin: '',
  personFacebook: '',
  personInstagram: '',
  ipAddress: '',
  code: string.generateAlphaNumericCode({ digits: 6 }),
};


// Project
const project = {
  cost: 0,
  name: '',
  notes: '',
  endDate: '',
  address: '',
  imageUrl: '',
  clientId: '',
  partnerId: '',
  startDate: '',
  description: '',
  status: projectStatus.finished,
};


// ClientPerson
const clientPerson = {
  name: '',
  ...helperContactProperties,
  notes: '',
  clientId: '',
  partnerId: '',
};


// Partner
const partner = {
  name: '',
  imageUrl: '',
  portalUrl: '',
  preferredLanguage: '',
  ...helperContactProperties,
  notes: '',
};

const templates = {
  event,
  partner,
  project,
  referral,
  clientPerson,
  contactMessage,
  referralProgram,
  clientv2: () => ({...clientv2, code: string.generateAlphaNumericCode({ digits: 3 })}),
};

export default templates;
