import { useEffect, useRef, useState } from "react";
import Icon from "../Icon/Icon";
import { icon } from "../../data/constants";

const changeSlide = ({children, setCurrentIndex, intendedIndex}) => {
  const minIndex = intendedIndex < 0 ? children.length-1 : intendedIndex;
  const newIndex = minIndex > children.length-1 ? 0 : minIndex;

  setCurrentIndex(newIndex);
};

const Slideshow = ({children, shouldChangeSlideAutomatically = true, intervalMilliseconds = 8 * 1000}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    clearTimeout(timeoutId);

    if(shouldChangeSlideAutomatically) {
      const newTimeoutId = setTimeout(() => {
        changeSlide({children, setCurrentIndex, intendedIndex: currentIndex+1});
      }, intervalMilliseconds);

      setTimeoutId(newTimeoutId);
    }
  }, [currentIndex, shouldChangeSlideAutomatically]);

  return (
    <>
      {children[currentIndex]}

      <div className='padding-top-bottom-xs flex center'>
        <div className='flex gap-md'>

          {children.map((child, index) => (
            <div key={index} onClick={() => setCurrentIndex(index)}>
              <Icon name={icon.circle} fill={index === currentIndex ? '#ffffff' : '#000000'} />
            </div>
          ))}

        </div>
      </div>
    </>
  );
};

export default Slideshow;