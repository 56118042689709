import string from "../../utils/string";
import dataLayer from "../../data/dataLayer";
import templates from "../../data/templates";
import iconPhone from "./assets/iconPhone.png";
import iconEmail from "./assets/iconEmail.png";
import iconWhatsapp from "./assets/iconWhatsapp.png";
import iconInstagram from "./assets/iconInstagram.png";
import { useEffect, useState } from "react";
import { globalTexts } from "../../utils/internationalization";

const updateContactMessage = ({contactMessage, setIsMessageSent}) => {
  if (contactMessage.contactInfo.length === 0 || contactMessage.message.length === 0) {
    return;
  }

  dataLayer.contactMessage.update({contactMessage});

  setTimeout(() => {
    setIsMessageSent(true);
  }, 700);
}

const ContactMethodItem = ({name, text, img, onClick}) => {
  return (
    <div className={`flex column gap-sm center padding-xs circle pointer background-primary hover-background-grayscale-1`} style={{ width: '128px', height: '128px', wordBreak: 'break-word' }} onClick={onClick}>
      <img src={img} alt={name} style={{ width: '32px', height: '32px' }} />
      <div className='text center size-sm'>{text}</div>
    </div>
  )
}

const PortalContactFlow = ({shouldRender = true, shouldScrollIntoView = true, contactInfo}) => {
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [contactMessage, setContactMessage] = useState({...templates.contactMessage, partnerId: contactInfo.partnerId});

  const elementHash = string.generateAlphaNumericCode({digits: 4});

  useEffect(() => {
    if (shouldScrollIntoView) {
      const contactFlow = document.getElementById(`contact-flow-${elementHash}`);
      contactFlow && contactFlow.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [shouldRender, shouldScrollIntoView]);

  if (!shouldRender) {
    return null
  };

  if (!contactInfo.partnerId) {
    console.error('Partner ID is a required field in contactInfo object');
    return null;
  }

  return (
    <div id={`contact-flow-${elementHash}`} className='flex justify-center background-secondary padding-md' style={{ scrollMarginTop: 48 }}>
      <div className='flex column gap-md' style={{ maxWidth: 768 }}>
        <div className='flex justify-center gap-md wrap'>
          <ContactMethodItem img={iconWhatsapp} text={globalTexts.writeToUsOnWhatsapp} onClick={() => {window.open(`https://wa.me/${contactInfo.phone}`, '_blank');}} />
          <ContactMethodItem img={iconPhone} text={`${globalTexts.callUsAt} ${contactInfo.phone}`} onClick={() => {window.open(`tel:${contactInfo.phone}`, '_blank');}} />
          <ContactMethodItem img={iconEmail} text={`${globalTexts.emailUsAt} ${contactInfo.email}`} onClick={() => {window.open(`mailto:${contactInfo.email}`, '_blank');}} />
          <ContactMethodItem img={iconInstagram} text={`${globalTexts.messageUsOnInsta}`} onClick={() => {window.open(`${contactInfo.instagram}`, '_blank');}} />
        </div>

        <div className='padding-top-lg text bold color-secondary'>{globalTexts.orWriteToUsDirectlyUsingThisForm}</div>
        <div className='flex justify-center gap-md wrap'>
          <input className='padding-sm width-100' value={contactMessage.contactInfo} onChange={({target}) => setContactMessage({...contactMessage, contactInfo: target.value})} placeholder={globalTexts.yourEmailOrPhoneNumberSoWeCanGetBackToYou} style={{ border: '3px solid var(--background-primary)' }} />
          <textarea className='padding-sm width-100' rows={4} value={contactMessage.message} onChange={({target}) => setContactMessage({...contactMessage, message: target.value})} placeholder={globalTexts.askForAQuoteForYourProjectLeaveUsAMessageOrAnyQuestions} style={{ border: '3px solid var(--background-primary)' }} />
          {isMessageSent ? <div className='text color-secondary text-center'>{globalTexts.weGotYourMessageThanksForContactingUs}</div> : <div className='padding-sm width-100 text center background-primary hover-background-grayscale-1 pointer' style={{ border: '3px solid var(--background-primary)' }} onClick={() => {updateContactMessage({contactMessage, setIsMessageSent})}}>{globalTexts.send}</div>}
        </div>
      </div>
    </div>
  )
}

export default PortalContactFlow;