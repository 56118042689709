import Icon from "../Icon/Icon";
import AppBar from "../AppBar/AppBar";
import QrCode from "../Referral/QrCode";
import templates from "../../data/templates";
import dataLayer from "../../data/dataLayer";
import ThumbInput from "../Input/ThumbInput";
import AddItemCard from "../Global/AddItemCard";
import ThumbStep from "../Interaction/ThumbStep";
import ProjectItem from "../Project/ProjectItem";
import ClientPersonItem from "./ClientPersonItem";
import ThingsFlow from "../Interaction/ThingsFlow";
import entityMetadata from "../../entities/entityMetadata";
import ThumbEditProperty from "../Input/ThumbEditProperty";
import ClientHelpToGetReferrals from "./ClientHelpToGetReferrals";
import IconAndPropertyEditable from "../Global/IconAndPropertyEditable";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { globalTexts } from "../../utils/internationalization";
import { icon, languageCodeLabels } from "../../data/constants";
// import QrCodev2 from "../Referral/QrCodev2";

const saveProject = async ({project, navigate}) => {
  const newProject = await dataLayer.project.update({project});
  navigate(`/project/${newProject.partnerId}/${newProject.clientId}/${newProject.id}`);
};

const savePerson = async ({person, navigate}) => {
  const newClientPerson = await dataLayer.clientPerson.update({person});
  navigate(`/client-person/${newClientPerson.partnerId}/${newClientPerson.clientId}/${newClientPerson.id}`);
};

const saveProperty = ({client, setEditingPropertyName}) => {
  dataLayer.clientv2.update({clientv2: client})
  setEditingPropertyName(null);
};

const Clientv2 = () => {
  const navigate = useNavigate();
  const {partnerId, clientId} = useParams();
  const [client, setClient] = useState({});
  const [project, setProject] = useState({...templates.project, partnerId, clientId});
  const [person, setPerson] = useState({...templates.clientPerson, partnerId, clientId});
  const [persons, setPersons] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingPerson, setIsAddingPerson] = useState(false);
  const [isAddingProject, setIsAddingProject] = useState(false);
  const [editingPropertyName, setEditingPropertyName] = useState(null);

  useEffect(() => {
    dataLayer.clientv2.onGet({partnerId, clientId, callback: ({document}) => setClient(document)});
    dataLayer.project.onListChange({partnerId, clientId, callback: ({documents}) => setProjects(documents)});
    dataLayer.clientPerson.onListChange({partnerId, clientId, callback: ({documents}) => setPersons(documents)});
  }, []);

  return (
    <>
      <AppBar backUrl={`/partner/${partnerId}`} />

      <div className='flex align-center gap-md padding-lg'>
        {true && client.imageUrl && (<img style={{width: '38%', maxHeight: '128px', objectFit: 'contain', filter: 'drop-shadow(0 0 2px rgba(70, 70, 70, 0.5))'}} src={`${process.env.REACT_APP_IMAGE_URL}/${client.imageUrl}`} />)}
        <div className='text bold center size-xxl'>{client.name}</div>
      </div>

      <div className='padding-lg'>
        <ClientHelpToGetReferrals />
      </div>

      <div className='padding-lg'>
        <div className='text size-xxl padding-top-md'>{globalTexts.projects}</div>
        <div className='text size-xs'>{globalTexts.clickTapToChangeOrAddInfo}</div>
        <div className='flex wrap gap-lg padding-top-md' x-style={{gap: '3%', rowGap: '16px'}}>
          {projects.map(project => <ProjectItem project={project} key={project.id} />)}

          <AddItemCard onClick={() => setIsAddingProject(true)} />
        </div>
      </div>

      {isAddingProject && (
        <ThingsFlow onFinish={() => saveProject({project, navigate})}>
          <ThumbStep>
            <ThumbInput label={entityMetadata.project.name.labelText} tip={entityMetadata.project.name.tipText} value={project.name} onChange={({target}) => setProject({...project, name: target.value})} />
          </ThumbStep>
        </ThingsFlow>
      )}

      <div className='padding-lg'>
        <div className='text size-xxl padding-top-md'>{globalTexts.people}</div>
        <div className='text size-xs'>{globalTexts.clickTapToChangeOrAddInfo}</div>
        <div className='flex wrap gap-lg padding-top-md' x-style={{gap: '3%', rowGap: '16px'}}>
          {persons.map(person => <ClientPersonItem clientPerson={person} key={person.id} />)}

          <AddItemCard onClick={() => setIsAddingPerson(true)} />
        </div>
      </div>

      {isAddingPerson && (
        <ThingsFlow onFinish={() => savePerson({person, navigate})}>
          <ThumbStep>
            <ThumbInput label={globalTexts.name} value={person.name} onChange={({target}) => setPerson({...person, name: target.value})} />
          </ThumbStep>
        </ThingsFlow>
      )}

      {/* <div className='text test'>Section for global documents associated to the client</div> */}

      <div className='padding-lg'>
        <div className='text size-xxl padding-top-md'>{globalTexts.info}</div>
        <div className='text size-xs'>{globalTexts.clickTapToChangeOrAddInfo}</div>

        <div className='flex column gap-lg padding-top-md'>
          <IconAndPropertyEditable size='xl' propertyName='name' value={client.name} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('name')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable size='xl' propertyName='preferredLanguage' value={languageCodeLabels[client.preferredLanguage]} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('preferredLanguage')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable size='xl' propertyName='stage' value={client.stage} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('stage')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable size='xl' propertyName='type' value={client.type} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('type')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='imageUrl' value={client.imageUrl} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('imageUrl')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='phone' value={client.phone} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('phone')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='email' value={client.email} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('email')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='instagram' value={client.instagram} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('instagram')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='address' value={client.address} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('address')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='website' value={client.website} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('website')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='facebook' value={client.facebook} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('facebook')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='twitter' value={client.twitter} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('twitter')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='linkedin' value={client.linkedin} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('linkedin')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='youtube' value={client.youtube} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('youtube')} entityMetadata={entityMetadata.clientv2} />
          <IconAndPropertyEditable propertyName='notes' value={client.notes} entity={client} setEntity={setClient} onClick={() => setEditingPropertyName('notes')} entityMetadata={entityMetadata.clientv2} />
        </div>
      </div>

      <div className='padding-top-xxxl'>
        <div className='padding-md flex center pointer' onClick={() => setIsDeleting(true)}><Icon name={icon.delete} stroke='var(--palette-action)' /></div>
        {isDeleting && (
          <div className='padding-md action text size-lg center' onClick={async () => {await dataLayer.clientv2.delete({partnerId, clientId}); navigate(`/partner/${partnerId}`)}}>{globalTexts.confirmDelete}</div>
        )}
      </div>

      <ThumbEditProperty propertyName={editingPropertyName} entity={client} setEntity={setClient} onFinish={() => saveProperty({client, setEditingPropertyName})} finishButtonText={globalTexts.save} entityMetadata={entityMetadata.clientv2} />
    </>
  )
};

export default Clientv2;