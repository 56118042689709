import React from "react";
import Thumb from "./Thumb";
import { languageCode } from "../../data/constants";
import { inter } from "../../utils/internationalization";

const texts = inter({
  next: {
    [languageCode.en]: 'Next',
    [languageCode.es]: 'Siguiente',
    [languageCode.fr]: 'Suivant',
  },
  finish: {
    [languageCode.en]: 'Finish',
    [languageCode.es]: 'Finalizar',
    [languageCode.fr]: 'Terminer',
  },
});

const ThumbStep = ({children, currentStep, setCurrentStep, nextStepElementOverride = null, finishButtonText, onFinish = null}) => {

  const NextAction = () => {
    if (nextStepElementOverride) {
      return React.cloneElement(nextStepElementOverride, {onClick: () => setCurrentStep(currentStep + 1)});
    }

    if (onFinish) {
      return (
        <div className="action" onClick={onFinish}>
          <div className="padding-md text center size-xl">{finishButtonText || texts.finish}</div>
        </div>
      )
    }

    return (
      <div className="action" onClick={() => setCurrentStep(currentStep + 1)}>
        <div className="padding-md text center size-xl">{texts.next}</div>
      </div>
    )
  }

  return (
    <>
      <Thumb>
        <div className="background-grayscale-0" style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.7)'}}>
          {children}
        </div>
        <NextAction />
      </Thumb>
    </>
  );
};

export default ThumbStep;