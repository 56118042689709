import ItemCard from '../Global/ItemCard';
import CircularImageFrameable from '../Img/CircularImageFrameable';
import IconAndDetailNonEditable from '../Global/IconAndDetailNonEditable';
import { useNavigate } from 'react-router-dom';
import { icon, languageCodeLabels } from '../../data/constants';

const Clientv2Item = ({clientv2}) => {
  const navigate = useNavigate();

  return (
    <ItemCard
      width={192}
      onClick={() => navigate(`/clientv2/${clientv2.partnerId}/${clientv2.id}`)}
    >
      <div className='flex column gap-sm padding-xs'>
        <div className='flex center'>
          {clientv2.imageUrl && <CircularImageFrameable image={`${process.env.REACT_APP_IMAGE_URL}/${clientv2.imageUrl}`} circleDimensions={96} imageHeight='100%' />}
        </div>

        <div className='text center bold size-xl color-grayscale-8'>{clientv2.type} {clientv2.name}</div>

        <div className='flex column gap-sm'>
          <IconAndDetailNonEditable icon={icon.phone} value={clientv2.phone} />
          <IconAndDetailNonEditable icon={icon.email} value={clientv2.email} />
          <IconAndDetailNonEditable icon={icon.address} value={clientv2.address} />
          <IconAndDetailNonEditable icon={icon.instagram} value={clientv2.instagram} />
          <IconAndDetailNonEditable icon={icon.company} value={clientv2.company} textClasses='bold' />
          <IconAndDetailNonEditable icon={icon.language} value={languageCodeLabels[clientv2.preferredLanguage]} />
          <IconAndDetailNonEditable icon={icon.fingerprint} value={clientv2.code} />
        </div>
      </div>
    </ItemCard>
  );
};

export default Clientv2Item;