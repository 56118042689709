import Icon from "../Icon/Icon";
import AppBar from "../AppBar/AppBar";
import dataLayer from "../../data/dataLayer";
import entityMetadata from "../../entities/entityMetadata";
import ThumbEditProperty from "../Input/ThumbEditProperty";
import IconAndPropertyEditable from "../Global/IconAndPropertyEditable";
import { icon } from "../../data/constants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { globalTexts } from "../../utils/internationalization";

const saveReferralProgram = async ({referralProgram, setEditingPropertyName}) => {
  await dataLayer.referralProgram.update({referralProgram});
  setEditingPropertyName(null);
};

const ReferralProgram = () => {
  const navigate = useNavigate();
  const {referralProgramId} = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [referralProgram, setReferralProgram] = useState({});
  const [editingPropertyName, setEditingPropertyName] = useState(null);

  useEffect(() => {
    dataLayer.referralProgram.onGet({referralProgramId, callback: ({document}) => setReferralProgram(document)});
  }, []);

  return (
    <>
      <AppBar backUrl={`/partner/${referralProgram.partnerId}`} />

      <div className='padding-lg'>
        <div className='text center size-xxl'>Referral Program {referralProgram.index}</div>

        <div className='flex column gap-md padding-top-xl'>
          <IconAndPropertyEditable propertyName='targetAudience' value={referralProgram.targetAudience} onClick={() => setEditingPropertyName('targetAudience')} entityMetadata={entityMetadata.referralProgram} />
          <IconAndPropertyEditable propertyName='description' value={referralProgram.description} onClick={() => setEditingPropertyName('description')} entityMetadata={entityMetadata.referralProgram} />
          <IconAndPropertyEditable propertyName='whatHelperStandsToWin' value={referralProgram.whatHelperStandsToWin} onClick={() => setEditingPropertyName('whatHelperStandsToWin')} entityMetadata={entityMetadata.referralProgram} />
          <IconAndPropertyEditable propertyName='whatNewClientStandsToWin' value={referralProgram.whatNewClientStandsToWin} onClick={() => setEditingPropertyName('whatNewClientStandsToWin')} entityMetadata={entityMetadata.referralProgram} />
        </div>
      </div>

      <div className='padding-md flex center pointer' onClick={() => setIsDeleting(true)}><Icon name={icon.delete} stroke='var(--palette-action)' /></div>
      {isDeleting && (
        <div className='padding-md action text size-lg center' onClick={async () => {await dataLayer.referralProgram.delete({referralProgramId}); navigate(`/partner/${referralProgram.partnerId}`)}}>{globalTexts.confirmDelete}</div>
      )}

      {editingPropertyName && (
        <ThumbEditProperty
          propertyName={editingPropertyName}
          entity={referralProgram}
          setEntity={setReferralProgram}
          onFinish={() => saveReferralProgram({referralProgram, setEditingPropertyName})}
          finishButtonText={globalTexts.save}
          entityMetadata={entityMetadata.referralProgram}
        />
      )}
    </>
  );
};

export default ReferralProgram;