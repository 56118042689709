import dataLayer from "../../data/dataLayer";
import templates from "../../data/templates";

const batchDataToInsert = [
  {name: 'example',	email: 'example@example.com', phone: '123 4567890', company:	'example'},
];

const insertData = () => {
  batchDataToInsert.map((dataToInsert, index) => {
    setTimeout(() => {
      const lead = {...templates.lead(), ...dataToInsert, partnerId: 'example'};
      dataLayer.lead.update({lead});
    }, 8 * 1000 * index);
  })

}

const BatchDataEntry = () => {
  return (
    <div className='text center padding-lg' onClick={insertData}>Insert data</div>
  );
};

export default BatchDataEntry;
