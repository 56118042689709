import Slideshow from '../Slideshow.js';
import ourWork1 from './assets/ourWork1.jpg';
import ourWork2 from './assets/ourWork2.jpg';
import ourWork3 from './assets/ourWork3.jpg';
import ourWork4 from './assets/ourWork4.jpg';
import PortalFooter from "../PortalFooter.js";
import PortalAppBar from "../PortalAppBar.js";
import services1 from './assets/services1.jpg';
import services2 from './assets/services2.jpg';
import services3 from './assets/services3.jpg';
import services4 from './assets/services4.jpg';
import services5 from './assets/services5.jpg';
import PortalWrapper from "../PortalWrapper.js";
import qualitySeal1 from '../assets/qualitySeal1.png';
import PortalContactFlow from "../PortalContactFlow.js";
import companyLogo from '../A&LHoods/assets/companyLogo.png';
import presentationImage2 from './assets/presentationImage2.jpg';
import presentationImage3 from './assets/presentationImage3.jpg';
import presentationImage5 from './assets/presentationImage5.jpg';
import presentationImage6 from './assets/presentationImage6.jpg';
import PortalProductOrService from '../PortalProductOrService.js';
import CircularImageAndContent from "../CircularImageAndContent.js";
import PortalInstaTestimonials from '../PortalInstaTestimonials.js';
import ContactFlowStartButton from '../PortalContactFlowStartButton.js';
import OverridesForPartnerCss from './assets/OverridesForPartnerCss.js';
import BackgroundImageAndContent from "../BackgroundImageAndContent.js";
import SquareImageListAndContent from "../SquareImageListAndContent.js";
import CircularImageAndContentV2 from '../CircularImageAndContentV2.js';
import PortalProductsAndServicesV2 from '../PortalProductsAndServicesV2.js';
import { useState } from 'react';
import { aAndLHoodsTexts } from './internationalization.js';
import isWeb from '../../../utils/responsive.js';
import PortalCustomerLogos from '../PortalCustomerLogos.js';

const contactInfo = {
  partnerId: 'LXtWxhSzxqEbhQHi4FCb',
  email: 'info@aandlhoods.com',
  phone: '+13108797753',
  instagram: 'https://www.instagram.com/aandlhoods',
}

const PortalAAndLHoods = () => {
  const [shouldRenderContactFlow, setShouldRenderContactFlow] = useState(false);
  const [shouldSlideshowChangeAutomatically, setShouldSlideshowChangeAutomatically] = useState(process.env.REACT_APP_ENABLE_ANIMATIONS === 'true');

  return (
    <PortalWrapper>
      <style>{OverridesForPartnerCss}</style>

      <PortalAppBar />

      <div>
        <Slideshow shouldChangeSlideAutomatically={shouldSlideshowChangeAutomatically}>
          <BackgroundImageAndContent image={presentationImage2} height={'600px'} left={'-290px'} additionalStyles={{minHeight: '600px'}}
            mobile={(
              <div className='flex column center gap-xl padding-top-xs padding-left-right-xl'>
                <img src={companyLogo} style={{width: 192}}></img>
                <div className='text center size-xxl uppercase' style={{textShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>{aAndLHoodsTexts.yourIdealRestaurantKitchenStartsHere}</div>
                <div className='text center size-xl' style={{textShadow: '0px 0px 10px rgba(0, 0, 0, 1)'}}>{aAndLHoodsTexts.weBringYourKitchenVisionToLife}</div>
                <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.howCanWeHelp} />
              </div>
            )}
            web={(
              <div className='flex column center gap-md padding-left-right-xl'>
                <div className='text center size-xxl uppercase'>{aAndLHoodsTexts.yourIdealRestaurantKitchenStartsHere}</div>
                <img src={companyLogo} style={{width: 192}}></img>
                <div className='text center size-xl'>{aAndLHoodsTexts.weBringYourKitchenVisionToLife}</div>
                <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.howCanWeHelp} />
              </div>
            )}
          />

          <div className='padding-xxl background-secondary'>
            <CircularImageAndContentV2 image={presentationImage6} circleDimensions={384} imageDimensions={'160%'} top={'-120px'} left={'-45px'} colorClass='color-secondary'>
              <div className='flex column center gap-md padding-lg'>
                <img src={qualitySeal1} style={{width: '128px', transform: 'rotate(14deg)'}}></img>
                <div className='text bold center size-xl' style={{zIndex: 1}}>{aAndLHoodsTexts.over20PlusYearsOfExpertiseAcrossCalifornia}</div>
              </div>
              <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.getInTouchNow} />
            </CircularImageAndContentV2>
          </div>

          <BackgroundImageAndContent image={presentationImage5} height={'600px'} left={'-420px'} additionalStyles={{minHeight: '600px'}}
            mobile={(
              <div className='flex column center gap-md padding-left-right-xl' style={{height: 600}}>
                <div className='text bold center size-xxl' style={{textShadow: '0px 0px 10px rgba(0, 0, 0, 0.7)'}}>{aAndLHoodsTexts.weCreateEfficientFunctionalKitchensTailoredToYourNeeds}</div>
                <img src={companyLogo} style={{width: 128}}></img>
                <div className='text center size-lg' style={{textShadow: '0px 0px 6px rgba(0, 0, 0, 1)'}}>{aAndLHoodsTexts.whetherYoureOpeningANewRestaurantOrUpgradingAnExistingOne}</div>
                <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.letUsHelpYou} />
              </div>
            )}
            web={(
              <div className='flex column center gap-lg padding-left-right-xl'>
                <img src={companyLogo} style={{width: 128}}></img>
                <div className='text bold center size-xxl'>{aAndLHoodsTexts.weCreateEfficientFunctionalKitchensTailoredToYourNeeds}</div>
                <div className='text center size-xl'>{aAndLHoodsTexts.whetherYoureOpeningANewRestaurantOrUpgradingAnExistingOne}</div>
                <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.letUsHelpYou} backgroundClass='background-secondary' colorClass='color-tertiary' />
              </div>
            )}
          />

          <div className='padding-xxl background-secondary'>
            <CircularImageAndContent image={presentationImage3} circleDimensions={384} imageDimensions={'135%'} top={'-48px'} colorClass='color-secondary'>
              <div className='flex column center gap-md padding-lg'>
                <div className='text bold center size-xl'>{aAndLHoodsTexts.restaurantOwnersTrustTheCustomKitchenDesignsExpertInstallationsAndReliabilityWeDeliver}</div>
              </div>

              <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.getInTouchNow} />
            </CircularImageAndContent>
          </div>

          <div className='padding-top-bottom-xxl'>
            <SquareImageListAndContent
              images={[{src: ourWork2, dimensions: '150%', top: '-25%', left: '-50%'}, {src: ourWork4, dimensions: '150%', top: '-25%', left: '-34%'}, {src: ourWork3, dimensions: '150%', top: '-25%', left: '-22%'}, {src: ourWork1, dimensions: '166%', top: '-33%', left: '-13%'}]}
              squareDimensions={384}
              rotateEvery={8000}
            >
              <div className='flex column center gap-md padding-top-xxl padding-left-right-xxl'>
                <div className='text bold center size-xl'>{aAndLHoodsTexts.ensureSafetyAndEfficiencyWithAAndLHoods}</div>
                <div className='text center size-xl'>{aAndLHoodsTexts.weHandleEverythingFromDetailedDesignsToTheHighestQualityMaterials}</div>
                <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.whatCanWeHelpYouWith} />
              </div>
            </SquareImageListAndContent>
          </div>
        </Slideshow>
      </div>

      <PortalContactFlow shouldRender={shouldRenderContactFlow} contactInfo={contactInfo} />

      <div className='padding-xl background-secondary'>
        <PortalCustomerLogos textColorClass='color-secondary' contactInfo={contactInfo} customerLogos={[
          <img className='circle' src='https://live.staticflickr.com/65535/54279767543_dfe952c80e_c.jpg' style={{height: 128}} alt='logo' />,
          <img className='circle' src='https://live.staticflickr.com/65535/54279761639_7f7824100b_n.jpg' style={{height: 128}} alt='logo' />,
          <img className='circle' src='https://live.staticflickr.com/65535/54210992819_70894cd22c_b.jpg' style={{height: 128}} alt='logo' />,
          <img className='circle' src='https://live.staticflickr.com/65535/54258645441_5049aedd46_k.jpg' style={{height: 128}} alt='logo' />,
          <img className='circle' src='https://live.staticflickr.com/65535/54279807429_18ac8ca8c5_b.jpg' style={{height: 128}} alt='logo' />,
          <img className='circle' src='https://live.staticflickr.com/65535/54195694871_ed2c6ef0af_h.jpg' style={{height: 128}} alt='logo' />,
          <img className='circle' src='https://live.staticflickr.com/65535/54211128060_b3c7756341.jpg' style={{height: 128}} alt='logo' />,
        ]} />
      </div>

      <div className='padding-xl background-secondary'>
        <PortalInstaTestimonials colorClass='color-secondary' />
      </div>

      <div className='padding-top-bottom-xl'>
        <PortalProductsAndServicesV2 contactInfo={contactInfo}>
          <PortalProductOrService image={services1} title={aAndLHoodsTexts.kitchenDesignConsultation}>
            <div className='flex column gap-md'>
              <div className='flex justify-center wrap gap-xl'>
                <div className={`padding-sm background-quaternary color-quaternary text center ${isWeb() ? 'size-xl width-25' : 'size-lg width-33'} word-wrap`}>{aAndLHoodsTexts.licensedKitchenDesign}</div>
                <div className={`padding-sm background-tertiary color-tertiary text center ${isWeb() ? 'size-xl width-25' : 'size-lg width-33'} word-wrap`}>{aAndLHoodsTexts.customKitchenLayouts}</div>
                <div className={`padding-sm background-quaternary color-quaternary text center ${isWeb() ? 'size-xl width-25' : 'size-lg width-33'} word-wrap`}>{aAndLHoodsTexts.tailoredSolutions}</div>
                <div className={`padding-sm background-tertiary color-tertiary text center ${isWeb() ? 'size-xl width-25' : 'size-lg width-33'} word-wrap`}>{aAndLHoodsTexts.stainlessSteelExpertise}</div>
              </div>

              <div className='text size-lg'>{aAndLHoodsTexts.weCreateThePerfectKitchenLayoutForYourRestaurantsNeeds}</div>
              <div className='text size-lg'>{aAndLHoodsTexts.weDesignKitchensWithMaxEfficiencyWorkflowAndFunctionality}</div>
              <div className='text size-lg'>{aAndLHoodsTexts.asAFullLicensedAndInsuredCompanyWeBringBothExpertiseAndLegalComplianceToEveryProject}</div>
              <div className='text size-lg'>{aAndLHoodsTexts.ourGoalIsToCreateAKitchenThatMeetsYourNeedsAndSupportsTheGrowthOfYourBusinessForYearsToCome}</div>
            </div>
          </PortalProductOrService>
          <PortalProductOrService image={services2} title={aAndLHoodsTexts.customHoodInstallation} />
          <PortalProductOrService image={services3} title={aAndLHoodsTexts.kitchenExhaustFanSystem} />
          <PortalProductOrService image={services4} title={aAndLHoodsTexts.maintenanceRepairAndExpertCleaning} />
          <PortalProductOrService image={services5} title={aAndLHoodsTexts.fireSprinklerSystemsInstallation} />
        </PortalProductsAndServicesV2>
      </div>

      <PortalFooter companyLogo={companyLogo} />
    </PortalWrapper>
  )
}

export default PortalAAndLHoods;