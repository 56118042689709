import Icon from "../Icon/Icon";
import { icon } from "../../data/constants";
import { inter } from "../../utils/internationalization";

const PortalFooter = ({companyLogo}) => {
  return (
    <div className='footer background-secondary color-secondary'>
      <div className='flex center padding-top-lg'>
        <img src={companyLogo} style={{width: '180px'}} alt='placeholder logo' />
      </div>

      <div className='flex justify-center gap-lg padding-lg'>
        <div>
          <div className='text title uppercase color-tertiary'>{texts.company}</div>
          <div className='text'>{texts.aboutUs}</div>
        </div>
        <div>
          <div className='text title uppercase color-tertiary'>{texts.kitchenHoodInstallations}</div>
        </div>
        <div>
          <div className='text title uppercase color-tertiary'>{texts.contactUs}</div>
          <div className='flex gap-xxs'>
            <Icon name={icon.whatsapp} stroke="#006eff" />
            <div className='text'>{texts.whatsapp}</div>
          </div>

          <div className='flex gap-xxs'>
            <Icon name={icon.phone} stroke="#006eff" />
            <div className='text'>{texts.phone}</div>
          </div>

          <div className='flex gap-xxs'>
            <Icon name={icon.email} stroke="#006eff" />
            <div className='text'>{texts.email}</div>
          </div>

          <div className='flex gap-xxs'>
            <Icon name={icon.location} stroke="#006eff" />
            <div className='text'>{texts.address}</div>
          </div>
        </div>
      </div>

      <div className='flex justify-center gap-lg padding-lg'>
        <div>
          <div className='text title uppercase color-tertiary'>{texts.solutionsByIndustry}</div>
          <div className='text'>{texts.restaurants}</div>
          <div className='text'>{texts.kitchens}</div>
        </div>
      </div>

      <div className='line'></div>

      <div className='flex center gap-lg padding-lg'>
        <div className='text'>{`A&L Commercial Hood Systems | ${texts.allRightsReserved} © 2024 | Flow Kaching`}</div>
        <div className='flex gap-xs'>
          <Icon name={icon.facebook} stroke='var(--text-tertiary)' />
          <Icon name={icon.instagram} stroke='var(--text-tertiary)' />
          <Icon name={icon.twitter} stroke='var(--text-tertiary)' />
          <Icon name={icon.youtube} stroke='var(--text-tertiary)' />
          <div className='text size-xl'>🇺🇸</div>
        </div>
      </div>
    </div>
  )
}

const texts = inter({
  company: {
    en: 'Company',
    es: 'Empresa',
    fr: 'Entreprise',
  },
  aboutUs: {
    en: 'About us',
    es: 'Sobre nosotros',
    fr: 'À propos de nous',
  },
  services: {
    en: 'Services',
    es: 'Servicios',
    fr: 'Services',
  },
  kitchenHoodInstallations: {
    en: 'Kitchen Hood Installations',
    es: 'Instalaciones de Hoods de Cocina',
    fr: 'Installations de Hoods de Cuisine',
  },
  contactUs: {
    en: 'Contact us',
    es: 'Contáctanos',
    fr: 'Contactez-nous',
  },
  email: {
    en: 'Email',
    es: 'Email',
    fr: 'Email',
  },
  phone: {
    en: 'Phone',
    es: 'Teléfono',
    fr: 'Téléphone',
  },
  address: {
    en: 'Address',
    es: 'Dirección',
    fr: 'Adresse',
  },
  whatsapp: {
    en: 'Whatsapp',
    es: 'Whatsapp',
    fr: 'Whatsapp',
  },
  solutionsByIndustry: {
    en: 'Solutions By Industry',
    es: 'Soluciones por Industria',
    fr: 'Solutions par Industrie',
  },
  restaurants: {
    en: 'Restaurants',
    es: 'Restaurantes',
    fr: 'Restaurants',
  },
  kitchens: {
    en: 'Kitchens',
    es: 'Cocinas',
    fr: 'Cuisines',
  },
  allRightsReserved: {
    en: 'All Rights Reserved',
    es: 'Todos los derechos reservados',
    fr: 'Tous droits réservés',
  },
});

export default PortalFooter;