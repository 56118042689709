const ItemTwoCards = ({children, width = 128, title, onClick}) => {
  const conditionalClasses = {
    pointer: onClick ? 'pointer' : '',
  };

  return (
    <div className={`rounded-xs background-grayscale-8 ${conditionalClasses.pointer}`} style={{width}} onClick={onClick}>
      <div className='color-grayscale-0'>{title}</div>

      {/* {project.imageUrl && <img src={`${process.env.REACT_APP_IMAGE_URL}/${project.imageUrl}`} className='padding-top-xs' style={{width: '128px', height: '64px'}} />} */}

      <div className='flex column gap-xs background-grayscale-4 padding-xs rounded-xs' style={{position: 'relative', top: 8, left: 8,}}>
        {children}
      </div>
    </div>
  );
};

export default ItemTwoCards;