import Icon from '../Icon/Icon';
import bgPurpleRoughShimmer2 from '../../assets/img/bgPurpleRoughShimmer2.jpg';
import { useNavigate } from 'react-router-dom';
import { icon, languageCode } from '../../data/constants';
import { globalTexts } from '../../utils/internationalization';

const changeLanguage = ({code}) => {
  localStorage.setItem('languageCode', code);
  window.location.reload();
}

const AppBar = ({backUrl, backText}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className='background-grayscale-3'>
        <div className='flex justify-between padding-xxs padding-left-right-sm'>
          <div className='flex center pointer' onClick={() => navigate(backUrl)}>
            {backUrl ? <Icon name={icon.arrowLeft} dimensions={36} stroke='var(--palette-action-secondary)' /> : <div className='text size-lg uppercase color-action-secondary'>{backText}</div>}
          </div>

          <div className='flex align-center gap-xs'>
            <div className='text bold size-md'>{globalTexts.language}</div>
            {Object.keys(languageCode).map(code => (
              <div
                key={code}
                className={`${languageCode[localStorage.getItem('languageCode')] === code ? 'background-secondary color-grayscale-0' : 'background-grayscale-0  hover-grayscale-2'} padding-xs rounded-xxs pointer`}
                onClick={() => changeLanguage({code})}
              ><div className='text size-sm'>{code.toUpperCase()}</div></div>
            ))}
          </div>
        </div>

        <div style={{height: '8px', overflow: 'hidden'}}>
          <img src={bgPurpleRoughShimmer2} alt='' />
        </div>
      </div>
    </>
  );
};

export default AppBar;