import { projectStatus } from "../data/constants";

/*
  receive a JSON object like:

  {
    something: {
      en: 'something',
      es: 'algo',
      fr: 'quelque chose',
    },
    somethingElse: {
      en: 'something else',
      es: 'algo más',
      fr: 'autre chose',
    },
  }

  return object with string values on language code set in Local Storage like:
  {
    something: 'something',
    somethingElse: 'something else',
  }
*/
const inter = (texts) => {
  const result = {};

  Object.keys(texts).forEach((key) => {
    result[key] = texts[key][localStorage.getItem('languageCode')];
  });

  return result;
};


// Actions
const globalTextsActions = {
  contactUsToAskAboutThisService: {
    en: 'Contact us to ask about this service',
    es: 'Contáctanos para preguntar sobre este servicio',
    fr: 'Contactez-nous pour demander des informations sur ce service',
  },
  contactUsToAskAboutThisProduct: {
    en: 'Contact us to ask about this product',
    es: 'Contáctanos para preguntar sobre este producto',
    fr: 'Contactez-nous pour demander des informations sur ce produit',
  },
  send: {
    en: 'Send',
    es: 'Enviar',
    fr: 'Envoyer',
  },
  copyText: {
    en: 'Copy text',
    es: 'Copiar texto',
    fr: 'Copier le texte',
  },
  copyLink: {
    en: 'Copy link',
    es: 'Copiar enlace',
    fr: 'Copier le lien',
  },
  save: {
    en: 'Save',
    es: 'Guardar',
    fr: 'Enregistrer',
  },
  delete: {
    en: 'Delete',
    es: 'Eliminar',
    fr: 'Supprimer',
  },
  filter: {
    en: 'Filter',
    es: 'Filtrar',
    fr: 'Filtrer',
  },
  confirmDelete: {
    en: 'Confirm delete',
    es: 'Confirmar eliminación',
    fr: 'Confirmer la suppression',
  },
  createNewClient: {
    en: 'Create new client',
    es: 'Crear nuevo cliente',
    fr: 'Créer un nouveau client',
  },
  createNewReferralProgram: {
    en: 'Create new referral program',
    es: 'Crear nuevo programa de referidos',
    fr: 'Créer un nouveau programme de références',
  },
  emptyValueClickToEdit: {
    en: 'Empty. Click to edit',
    es: 'Vacío. Clic para editar',
    fr: 'Vide. Cliquez pour modifier',
  },
  clickTapToChangeOrAddInfo: {
    en: 'Click or tap to change or add info',
    es: 'Click o toque para cambiar o agregar info',
    fr: 'Cliquez ou touchez pour modifier ou ajouter des infos',
  },
};


// Property names
const globalTextsPropertyNames = {
  code: {
    en: 'Code',
    es: 'Código',
    fr: 'Code',
  },
  name: {
    en: 'Name',
    es: 'Nombre',
    fr: 'Nom',
  },
  projects: {
    en: 'Projects',
    es: 'Proyectos',
    fr: 'Projets',
  },
  cost: {
    en: 'Cost',
    es: 'Costo',
    fr: 'Coût',
  },
  stage: {
    en: 'Commercial stage',
    es: 'Etapa comercial',
    fr: 'Etape commerciale',
  },
  targetAudience: {
    en: 'Target audience',
    es: 'Audiencia',
    fr: 'Public cible',
  },
  whatHelperStandsToWin: {
    en: 'What helper stands to win',
    es: 'Lo que el ayudante gana',
    fr: 'Ce que l\'aideur gagne',
  },
  whatNewClientStandsToWin: {
    en: 'What new client stands to win',
    es: 'Lo que el nuevo cliente gana',
    fr: 'Ce que le nouveau client gagne',
  },
  personOrCompanyToAddAsClient: {
    en: 'Person or company to add as client',
    es: 'Persona o empresa a agregar como cliente',
    fr: 'Personne ou entreprise à ajouter comme client',
  },
  type: {
    en: 'Type',
    es: 'Tipo',
    fr: 'Type',
  },
  company: {
    en: 'Company',
    es: 'Empresa',
    fr: 'Entreprise',
  },
  email: {
    en: 'Email',
    es: 'Email',
    fr: 'Email',
  },
  phone: {
    en: 'Phone',
    es: 'Teléfono',
    fr: 'Téléphone',
  },
  address: {
    en: 'Address',
    es: 'Dirección',
    fr: 'Adresse',
  },
  website: {
    en: 'Website',
    es: 'Sitio web',
    fr: 'Site web'
  },
  partnerPortalUrl: {
    en: 'Partner portal URL',
    es: 'URL del portal del partner',
    fr: 'URL du portail du partenaire',
  },
  instagram: {
    en: 'Instagram',
    es: 'Instagram',
    fr: 'Instagram',
  },
  twitter: {
    en: 'Twitter',
    es: 'Twitter',
    fr: 'Twitter',
  },
  youtube: {
    en: 'Youtube',
    es: 'Youtube',
    fr: 'Youtube',
  },
  linkedin: {
    en: 'Linkedin',
    es: 'Linkedin',
    fr: 'Linkedin',
  },
  facebook: {
    en: 'Facebook',
    es: 'Facebook',
    fr: 'Facebook',
  },
  notes: {
    en: 'Key info to remember',
    es: 'Info clave para recordar',
    fr: 'Infos clés à retenir',
  },
  startDate: {
    en: 'Start date',
    es: 'Fecha de inicio',
    fr: 'Date de début',
  },
  endDate: {
    en: 'End date',
    es: 'Fecha de finalización',
    fr: 'Date de fin',
  },
  description: {
    en: 'Description',
    es: 'Descripción',
    fr: 'Description',
  },
  status: {
    en: 'Status',
    es: 'Estado',
    fr: 'Statut',
  },
  imageUrl: {
    en: 'Image URL',
    es: 'URL de la imagen',
    fr: 'URL de l\'image',
  },
  language: {
    en: 'Language',
    es: 'Idioma',
    fr: 'Langue',
  },
  languageToSendMessages: {
    en: 'Language to send messages',
    es: 'Idioma para enviar mensajes',
    fr: 'Langue pour envoyer des messages',
  },
};


const globalTextsPageTitles = {
  theseAreBrandsThatChoseUsAndTrustUs: {
    en: 'These brands chose us and trust us',
    es: 'Estas marcas nos eligieron y confían en nosotros',
    fr: 'Ce sont des marques qui nous ont choisies et qui nous font confiance',
  },
  referralPrograms: {
    en: 'Referral programs',
    es: 'Programas de referidos',
    fr: 'Programmes de références',
  },
  leads: {
    en: 'Leads',
    es: 'Prospectos',
    fr: 'Prospects',
  },
  clients: {
    en: 'Clients',
    es: 'Clientes',
    fr: 'Clients',
  },
  details: {
    en: 'Details',
    es: 'Detalles',
    fr: 'Détails',
  },
  info: {
    en: 'Info',
    es: 'Info',
    fr: 'Info',
  },
  people: {
    en: 'People',
    es: 'Personas',
    fr: 'Personnes',
  },
  projects: {
    en: 'Projects',
    es: 'Proyectos',
    fr: 'Projets',
  },
  ourClientsHaveALotOfGoodStuffToSayAboutUs: {
    en: 'Our clients have a lot of good stuff to say about us',
    es: 'Nuestros clientes tienen muchas cosas buenas qué decir sobre nosotros',
    fr: 'Nos clients ont beaucoup de choses bonnes à dire sur nous',
  },
  checkOutOurProducts: {
    en: 'Check out our products',
    es: 'Echa un vistazo a nuestros productos',
    fr: 'Checkez nos produits',
  },
  checkOutOurServices: {
    en: 'Check out our services',
    es: 'Dale un vistazo a nuestros servicios',
    fr: 'Checkez nos services',
  },
}


// Info
const globalTextsInfo = {
  moreInfo: {
    en: 'See more info',
    es: 'Ver más info',
    fr: 'Voir plus d\'infos',
  },
  weGotYourMessageThanksForContactingUs: {
    en: 'We got your message 👍 Thanks for contacting us, we\'ll get back to you as soon as possible.',
    es: 'Recibimos tu mensaje 👍 Gracias por contactarnos, te contactaremos lo antes posible.',
    fr: 'On a reçu votre message 👍 Merci pour nous contacter, on vous contactera le plus tôt possible.',
  },
  yourEmailOrPhoneNumberSoWeCanGetBackToYou: {
    en: 'Your email or phone number so we can get back to you',
    es: 'Tu email o teléfono para poder contactarte',
    fr: 'Votre email ou numéro de téléphone pour vous contacter',
  },
  writeToUsOnWhatsapp: {
    en: 'Write to us on Whatsapp',
    es: 'Escribenos en Whatsapp',
    fr: 'Écrivez-nous sur WhatsApp',
  },
  callUsAt: {
    en: 'Call us at',
    es: 'Llámanos al',
    fr: 'Appelez-nous au',
  },
  emailUsAt: {
    en: 'Email us at',
    es: 'Envíanos un email a',
    fr: 'Écrivez-nous à',
  },
  messageUsOnInsta: {
    en: 'Message us on insta',
    es: 'Escríbenos en insta',
    fr: 'Écrivez-nous sur insta',
  },
  orWriteToUsDirectlyUsingThisForm: {
    en: 'Or write to us using this form',
    es: 'O escríbenos usando este formulario',
    fr: 'O écrivez-nous en utilisant ce formulaire',
  },
  askForAQuoteForYourProjectLeaveUsAMessageOrAnyQuestions: {
    en: 'Ask for a quote for your project, leave us a message or any questions and we\'ll get back to you as soon as possible.',
    es: 'Pide un presupuesto para tu proyecto, deja un mensaje o cualquier pregunta y te contactaremos lo antes posible.',
    fr: 'Demande un devis pour votre projet, laissez-nous un message ou toute question et nous vous contacterons le plus tôt possible.',
  },
  whatTypeOfPeopleWouldBeIncentivizedByTheRewardsInThisReferralProgram: {
    en: 'What type of people would be incentivized by the rewards in this referral program',
    es: 'Qué tipo de personas serían incentivadas por las recompensas en este programa de referidos',
    fr: 'Quel type de personnes serait encouragées par les récompenses dans ce programme de références',
  },
  listHereTheThingsAPersonWouldBeGiven: {
    en: 'List here the things a person would be given for their efforts for helping to bring new clients to the business',
    es: 'Lista aquí las cosas que una persona recibiría por su esfuerzo para ayudar a traer nuevos clientes a la empresa',
    fr: 'Listez ici les choses que une personne recevrait pour leurs efforts pour aider à faire venir de nouveaux clients à l\'entreprise',
  },
  listHereTheThingsANewClientWouldBeGiven: {
    en: 'List here the things a new client would be given when they come to the business and say someone referred them',
    es: 'Lista aquí las cosas que un nuevo cliente recibiría cuando venga a la empresa y diga que alguien lo referió',
    fr: 'Listez ici les choses que un nouveau client recevrait quand il viendrait à l\'entreprise et dirait qu\'une personne l\'a référé',
  },
  okLetsHelpThisCustomerBringSomeReferrals: {
    en: 'OK, let\'s help this customer bring some referrals. Here are some options:',
    es: 'OK, ayudemos a este cliente a traer referidos. Aquí hay algunas opciones:',
    fr: 'OK, aidons ce client à faire venir des références. Voici quelques options:',
  },
  anotherOptionIsToHaveTheCustomerScanThisQRCode: {
    en: 'Another option is to have the customer scan this QR code - there, they will find instructions on how to bring referrals',
    es: 'Otra opción es que el cliente escanee este código QR - allí, encontrarán instrucciones sobre cómo traer referidos',
    fr: 'Une autre option est de faire en que le client scanne ce QR code - là, il trouvera des instructions sur comment faire venir des références',
  },
  anotherOptionIsToGiveTheCustomerThisTextAndTellThemToShareIt: {
    en: 'Another option is to give the customer this text and tell them to share it with their friends or family',
    es: 'Otra opción es dar el cliente este texto y decirles que lo compartan con sus amigos o familia',
    fr: 'Une autre option est de donner ce texte au client et de le dire de le partager avec ses amis ou sa famille',
  },
  oneOptionIsToSendThemThisLinkAndTellThemToFollowInstructionsThere: {
    en: 'One option is to send them this link and tell them to follow instructions there',
    es: 'Una opción es enviarles este enlace y decirles que sigan las instrucciones allí',
    fr: 'Une option est de leur envoyer ce lien et de le dire de suivre les instructions là',
  },
  thisIsAUrlPartForReactRouter: {
    en: 'This is a URL part for React Router. E.g. for the route "/portal/AAndLHoods/:partnerId" the correct value to set here is "AAndLHoods"',
    es: 'Esto es una parte de la URL para React Router. E.g. para la ruta "/portal/AAndLHoods/:partnerId" el valor correcto a poner aquí es "AAndLHoods"',
    fr: 'C\'est une partie de l\'URL pour React Router. E.g. pour la route "/portal/AAndLHoods/:partnerId" la valeur correcte à mettre ici est "AAndLHoods"',
  },
  egPaulRevereOrHopeHarborSolutionsInc: {
    en: 'e.g. Paul Revere or HopeHarbor Solutions Inc.',
    es: 'e.g. María Juliana Martínez o Soluciones Esperanza LTDA',
    fr: 'e.g. Jean Dupont ou Solutions d\'Espérance S.A.',
  },
  whatTypeOfWorkWasDoneDuringThisProject: {
    en: 'What type of work was done during this project',
    es: 'Qué tipo de trabajo se hizo durante este proyecto',
    fr: 'Quel type de travail a été fait pendant ce projet',
  },
  projectNameOrPlaceWhereJobWasDone: {
    en: 'Project name or place where job was done',
    es: 'Nombre del proyecto o lugar donde se hizo el trabajo',
    fr: 'Nom du projet ou lieu où le travail a été fait',
  },
  weWillSendMessagesToThisWhatsapp: {
    en: 'We will send messages to this WhatsApp',
    es: 'Vamos a enviar mensajes a este WhatsApp',
    fr: 'On va envoyer des messages à ce WhatsApp',
  },
  ifTheClientHasNoWhatsappWeWillSendMessagesToThisEmail: {
    en: 'If the client has no WhatsApp, we will send messages to this email',
    es: 'Si el cliente no tiene WhatsApp, vamos a enviar mensajes a este correo',
    fr: 'Si le client n\'a pas de WhatsApp, on va envoyer des messages à cet email',
  },
  organizeYourClientsInDifferentGroups: {
    en: 'Organize your clients in different groups for example according to their industry or the type of work you do for them',
    es: 'Organiza tus clientes en diferentes grupos para, por ejemplo, organizarlos por industria o tipo de trabajo que haces para ellos',
    fr: 'Organisez vos clients en différents groupes, par exemple en fonction de leur industrie ou du type de travail que vous réalisez pour eux',
  },
  selectAnEmojiAsARepresentationOfTypeAndAsAMeansToCreateGroupsOrCategories: {
    en: 'Select an emoji as a representation of the type and as a means to create groups or categories',
    es: 'Selecciona un emoji como representación del tipo y como medio para crear grupos o categorías',
    fr: 'Sélectionnez un emoji comme représentation du type et comme moyen de créer des groupes ou des catégories',
  },
};


// Constants
const globalTextsConstants = {
  [projectStatus.onHold]: {
    en: 'On hold',
    es: 'En espera',
    fr: 'En attente',
  },
  [projectStatus.cancelled]: {
    en: 'Cancelled',
    es: 'Cancelado',
    fr: 'Annulé',
  },
  [projectStatus.finished]: {
    en: 'Finished',
    es: 'Finalizado',
    fr: 'Terminé',
  },
  [projectStatus.exploring]: {
    en: 'Exploring',
    es: 'Explorando',
    fr: 'Explorant',
  },
  [projectStatus.inProgress]: {
    en: 'In progress',
    es: 'En progreso',
    fr: 'En cours',
  },
  [projectStatus.negotiating]: {
    en: 'Negotiating',
    es: 'Negociando',
    fr: 'Négociant',
  },
  [projectStatus.preparingToStart]: {
    en: 'Preparing to start',
    es: 'Preparando para iniciar',
    fr: 'En préparation pour commencer',
  },
};


const allGlobalTexts = {
  ...globalTextsInfo,
  ...globalTextsActions,
  ...globalTextsConstants,
  ...globalTextsPageTitles,
  ...globalTextsPropertyNames,
};


// Helper to temporarily import and check property names and then remove
const helperGlobalTexts = allGlobalTexts;

const globalTexts = inter(allGlobalTexts);

export { inter, globalTexts, helperGlobalTexts };