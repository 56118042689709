const PortalWrapperCss = `
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


/* Palette */
:root {
  --text-primary: #ffffff;
  --text-secondary: #000000;
  --text-tertiary: #a80e0e;
  --text-quaternary: #ffd138;
  --text-quinary: #9ca3af;

  --background-primary: #000000;
  --background-secondary: #ffffff;
  --background-tertiary: #a80e0e;
  --background-quaternary: #ffd138;
  --background-quinary: #9ca3af;

  --grayscale-0: #000000;
  --grayscale-1: #202020;
  --grayscale-2: #404040;
  --grayscale-3: #606060;
  --grayscale-4: #808080;
  --grayscale-5: #A0A0A0;
  --grayscale-6: #C0C0C0;
  --grayscale-7: #DCDCDC;
  --grayscale-8: #E8E8E8;
  --grayscale-9: #FFFFFF;
}

.color-primary { color: var(--text-primary); }
.color-secondary { color: var(--text-secondary); }
.color-tertiary { color: var(--text-tertiary); }
.color-quaternary { color: var(--text-quaternary); }
.color-quinary { color: var(--text-quinary); }

.background-primary { background-color: var(--background-primary); }
.background-secondary { background-color: var(--background-secondary); }
.background-tertiary { background-color: var(--background-tertiary); }
.background-quaternary { background-color: var(--background-quaternary); }
.background-quinary { background-color: var(--background-quinary); }

.hover-background-grayscale-0:hover { background-color: var(--grayscale-0); }
.hover-background-grayscale-1:hover { background-color: var(--grayscale-1); }
.hover-background-grayscale-2:hover { background-color: var(--grayscale-2); }
.hover-background-grayscale-3:hover { background-color: var(--grayscale-3); }
.hover-background-grayscale-4:hover { background-color: var(--grayscale-4); }
.hover-background-grayscale-5:hover { background-color: var(--grayscale-5); }
.hover-background-grayscale-6:hover { background-color: var(--grayscale-6); }
.hover-background-grayscale-7:hover { background-color: var(--grayscale-7); }
.hover-background-grayscale-8:hover { background-color: var(--grayscale-8); }
.hover-background-grayscale-9:hover { background-color: var(--grayscale-9); }


/* Globals */
body {
  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-primary);
}


/* Animations */
@keyframes colour-to-grayscale {
  from { filter: grayscale(0%); }
  to { filter: grayscale(100%); }
}
.colour-to-grayscale {
  animation: colour-to-grayscale 3s ease-in-out forwards;
}
@keyframes normal-to-brightness-66 {
  from { filter: brightness(100%); }
  to { filter: brightness(66%); }
}
.normal-to-brightness-66 {
  animation: normal-to-brightness-66 3s ease-in-out forwards;
}
@keyframes normal-to-brightness-50 {
  from { filter: brightness(100%); }
  to { filter: brightness(50%); }
}
.normal-to-brightness-50 {
  animation: normal-to-brightness-50 3s ease-in-out forwards;
}
@keyframes normal-to-brightness-33 {
  from { filter: brightness(100%); }
  to { filter: brightness(33%); }
}
.normal-to-brightness-33 {
  animation: normal-to-brightness-33 3s ease-in-out forwards;
}
@keyframes normal-to-brightness-10 {
  from { filter: brightness(100%); }
  to { filter: brightness(10%); }
}
.normal-to-brightness-10 {
  animation: normal-to-brightness-10 3s ease-in-out forwards;
}
@keyframes transparent-to-opaque {
  0% { opacity: 0; }
  30% { opacity: 0; }
  100% { opacity: 1; }
}
.transparent-to-opaque {
  animation: transparent-to-opaque 3s ease-in-out forwards;
}
@keyframes normal-to-bright-loop {
  0% { filter: brightness(100%); }
  10% { filter: brightness(150%); }
  20% { filter: brightness(100%); }
}
.normal-to-bright-loop {
  animation: normal-to-bright-loop 12s ease-in-out infinite;
}


/* Shapes */
.circle { border-radius: 50%; }
.pill { border-radius: 9999px; }
.line {
  border-bottom: 1px solid var(--text-tertiary);
}
.line-dark {
  border-bottom: 1px solid var(--text-tertiary);
}


/* Interaction */
input, textarea {
  color: var(--grayscale-2);
  font-family: 'Poppins', sans-serif;
  border: 0px solid var(--grayscale-4);
  border-radius: 0;
}


/* Typography */
.text.title {
  font-weight: 900;
  font-style: italic;
  font-family: 'Work Sans', sans-serif;
  text-shadow: 2px 2px 0 var(--grayscale-9);
}
.text.color-dark {
  color: var(--grayscale-8);
}
.text.color-dark.title {
  text-shadow: 2px 2px 0 var(--grayscale-4);
}
`;

export default PortalWrapperCss;