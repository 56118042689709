import { useNavigate } from "react-router-dom";
import IconAndDetailNonEditable from "../Global/IconAndDetailNonEditable";
import { icon } from "../../data/constants";
import { globalTexts } from "../../utils/internationalization";
import ItemTwoCards from "../Global/ItemTwoCards";

const ProjectItem = ({project}) => {
  const navigate = useNavigate();

  return (
    <ItemTwoCards
      title={(
        <>
          <div className='text bold size-lg padding-top-xs padding-left-right-xs'>{project.name}</div>
          {project.imageUrl && <img src={`${process.env.REACT_APP_IMAGE_URL}/${project.imageUrl}`} className='padding-top-xs' style={{width: '128px', height: '64px', objectFit: 'contain'}} />}
        </>
      )}
      onClick={() => navigate(`/project/${project.partnerId}/${project.clientId}/${project.id}`)}
    >
      <IconAndDetailNonEditable value={globalTexts[project.status]} icon={icon.tree} size='sm' />
      <IconAndDetailNonEditable value={project.cost} icon={icon.moneyInvoice} size='sm' />
      <IconAndDetailNonEditable value={project.address} icon={icon.address} size='sm' />
      <IconAndDetailNonEditable value={project.description} icon={icon.message} size='sm' />
      <IconAndDetailNonEditable value={project.startDate} icon={icon.calendar} size='sm' />
      <IconAndDetailNonEditable value={project.endDate} icon={icon.calendarCheck} size='sm' />
      <IconAndDetailNonEditable value={project.notes} icon={icon.cocktail} size='sm' />
    </ItemTwoCards>
  );
};

export default ProjectItem;
