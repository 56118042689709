import PortalContactFlow from "./PortalContactFlow";
import ContactFlowStartButton from "./PortalContactFlowStartButton";
import { useState } from "react";
import { globalTexts } from "../../utils/internationalization";
import { aAndLHoodsTexts } from "./A&LHoods/internationalization";

const PortalCustomerLogos = ({customerLogos, textColorClass, contactInfo}) => {
  const [shouldRenderContactFlow, setShouldRenderContactFlow] = useState(false);
  return (
    <>
      <div className={`text bold center size-xl ${textColorClass}`}>{globalTexts.theseAreBrandsThatChoseUsAndTrustUs}</div>

      <div className='padding-top-xl flex justify-center gap-md wrap'>
        {customerLogos.map((logo, index) => (
          <div key={index} className='text center size-xxl'>{logo}</div>
        ))}
      </div>

      <div className='padding-top-xl'>
        <ContactFlowStartButton contactFlowElementSetter={setShouldRenderContactFlow}  callToActionText={aAndLHoodsTexts.getInTouchToBecomeAnotherSatisfiedCustomer} />

        <PortalContactFlow shouldRender={shouldRenderContactFlow} contactInfo={contactInfo} />
      </div>
    </>
  )
}

export default PortalCustomerLogos;