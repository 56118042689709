import Icon from '../Icon/Icon';
import Footer from '../Footer/Footer';
import AppBar from '../AppBar/AppBar';
import dataLayer from '../../data/dataLayer';
import templates from '../../data/templates';
import ThumbInput from '../Input/ThumbInput';
import ThingList from '../ThingList/ThingList';
import ThumbStep from '../Interaction/ThumbStep';
import PartnerItem from '../Partner/PartnerItem';
import ThingsFlow from '../Interaction/ThingsFlow';
import entityMetadata from '../../entities/entityMetadata';
import { icon } from '../../data/constants';
import { useState, useEffect } from 'react';
import { inter } from '../../utils/internationalization';

const BrainFlow = () => {
  const [partners, setPartners] = useState([]);
  const [authToken, setAuthToken] = useState(null);
  const [partner, setPartner] = useState(templates.partner);
  const [isCreatingPartner, setIsCreatingPartner] = useState(false);

  useEffect(() => {
    setAuthToken(localStorage.getItem('authToken'));
    dataLayer.partner.onListChange({callback: ({documents}) => setPartners(documents)});
  }, []);

  if(authToken !== 'elephant-gorilla-horse') {
    return <input className='thumb padding-sm' type='text' value={authToken} onChange={({target}) => {setAuthToken(target.value); localStorage.setItem('authToken', target.value);}} />;
  }

  return (
    <>
      <AppBar />

      <div className='padding-top-bottom-sm'>
        <ThingList>
          {partners.map((partner, index) => <PartnerItem partner={partner} key={index} />)}
        </ThingList>
      </div>

      {!isCreatingPartner && (
        <div className='flex column'>
          <div className='flex align-center padding-xxs background-grayscale-3 pill' onClick={() => setIsCreatingPartner(true)}>
            <div className='flex center circle background-action' style={{width: '32px', height: '32px'}}><Icon name={icon.add} dimensions='22' /></div>
            <div className='text size-xl padding-left-right-sm'>{texts.addPartner}</div>
          </div>
        </div>
      )}

      {isCreatingPartner && (
        <ThingsFlow
          onFinish={() => dataLayer.partner.update({partner})}
          finishButtonText={texts.createPartner}
        >
          <ThumbStep>
            <ThumbInput label={entityMetadata.partner.name.labelText} tip={entityMetadata.partner.name.tipText} value={partner.name} onChange={({target}) => setPartner({...partner, name: target.value})} />
          </ThumbStep>
        </ThingsFlow>
      )}

      <Footer />
    </>
  );
};

const texts = inter({
  createPartner: {
    en: 'Create Partner',
    es: 'Crear Socio',
    fr: 'Créer un Partenaire',
  },
  addPartner: {
    en: 'Add Partner',
    es: 'Agregar Socio',
    fr: 'Ajouter un Partenaire',
  },
});

export default BrainFlow;