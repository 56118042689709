const ItemCard = ({children, width = 128, additionalClasses, onClick}) => {
  const conditionalClasses = {
    pointer: onClick ? 'pointer' : '',
  };

  return (
    <div className={`rounded-xxs background-grayscale-2 ${conditionalClasses.pointer} ${additionalClasses}`} style={{width, boxShadow: '0 0 4px 0 rgba(70,70,70, 0.2)'}} onClick={onClick}>
      {children}
    </div>
  );
};

export default ItemCard;