import PortalButton from "./PortalButton";

const ContactFlowStartButton = ({
  callToActionText = 'Contact us',
  backgroundClass = 'background-tertiary',
  textColorClass = 'color-secondary',
  textSizeClass = 'size-xl',
  contactFlowElementSetter,
  onClick
}) => {
  return (
    <PortalButton text={callToActionText} textTitleClass='title' textSizeClass={textSizeClass} textColorClass={textColorClass} backgroundClass={backgroundClass} onClick={() => {contactFlowElementSetter(true); onClick && onClick();}} />
  )
}

export default ContactFlowStartButton;