import Icon from "../Icon/Icon";
import { icon } from "../../data/constants";

const AddItemCard = ({onClick, width = 128, iconToShow = icon.add}) => {
  return (
    <div
      className='flex center background-action rounded-xxs pointer hover-action-secondary'
      style={{width, minHeight: width}}
      onClick={onClick}
    >
      <Icon name={iconToShow} />
    </div>
  );
};

export default AddItemCard;