import isWeb from "../../utils/responsive";

const FrameableCircleImage = ({image, circleDimensions, imageDimensions, top, left, objectFit = 'contain', filter = 'drop-shadow(0 0 4px rgba(70,70,70, 0.4))'}) => (
  <div style={{position: 'relative', width: circleDimensions, height: circleDimensions, borderRadius: '50%', overflow: 'hidden', filter}}>
    <img className='normal-to-bright-loop' src={image} style={{position: 'absolute', top, left, width: imageDimensions, height: imageDimensions, objectFit}} />
  </div>
)

const CircularImageAndContentV2 = ({ image, circleDimensions = 128, imageDimensions = '100%', top, left, children, backgroundClass, colorClass, objectFit }) => {
  if(!isWeb()) {
    return (
      <div className={`flex column center ${backgroundClass} ${colorClass}`}>
        <FrameableCircleImage image={image} circleDimensions={circleDimensions} imageDimensions={imageDimensions} top={top} left={left} objectFit={objectFit} />

        {children}
      </div>
    )
  }

  return (
    <div className={`flex center ${backgroundClass} ${colorClass}`}>
      <div className='flex center' style={{width: '50%'}}>
        <FrameableCircleImage image={image} circleDimensions={circleDimensions} imageDimensions={imageDimensions} top={top} left={left} objectFit={objectFit} />
      </div>

      <div style={{width: '50%'}}>
        {children}
      </div>
    </div>
  )
}

export default CircularImageAndContentV2;