import { globalTexts } from "../../utils/internationalization";
import isWeb from "../../utils/responsive";
import Card from "../Global/Card";
import QrCode from "../Referral/QrCode";

const OptionCard = ({optionNumber, optionText, children}) => {
  return (
    <Card additionalClasses={`${isWeb() ? 'width-33' : ''}`}>
      <div className='padding-md flex column gap-sm'>
        <div className='text center padding-left-right-xl'>
          {optionText}
        </div>

        <div className='text center padding-left-right-xl'>
          {children}
        </div>
      </div>
    </Card>
  )
}

const ClientHelpToGetReferrals = () => {
  return (
    <>
      <div className='text center size-xl padding-top-bottom-md'>{globalTexts.okLetsHelpThisCustomerBringSomeReferrals}</div>

      <div className={`flex ${isWeb() ? '' : 'column'} gap-lg`} style={{alignItems: 'start'}}>
        <OptionCard optionNumber={1} optionText={globalTexts.oneOptionIsToSendThemThisLinkAndTellThemToFollowInstructionsThere}>
          <div className='flex column gap-md'>
            <div className='text size-md action width-100 padding-xs'>{globalTexts.copyLink}</div>
            <textarea className='text center size-md word-wrap width-100 padding-xs' value='https://www.flowkaching.com/testing'></textarea>
          </div>
        </OptionCard>

        <OptionCard optionNumber={2} optionText={globalTexts.anotherOptionIsToGiveTheCustomerThisTextAndTellThemToShareIt}>
          <div className='flex column gap-md'>
            <div className='text size-md action width-100 padding-xs'>{globalTexts.copyText}</div>
            <textarea className='text size-md word-wrap width-100 padding-xs' rows={6} value={'🎯 beautiful whatsapp-ready text goes here 🎉'} />
          </div>
        </OptionCard>

        <OptionCard optionNumber={3} optionText={globalTexts.anotherOptionIsToHaveTheCustomerScanThisQRCode}>
          <QrCode />
        </OptionCard>
      </div>
    </>
  )
}

export default ClientHelpToGetReferrals;