import instagramLinks from "./A&LHoods/instagramLinks";
import EmbededInstagramLink from "./EmbededInstagramLink";
import { globalTexts } from "../../utils/internationalization";
import { aAndLHoodsTexts } from "./A&LHoods/internationalization";

const PortalInstaTestimonials = ({colorClass}) => {
  return (
    <div className='flex justify-center'>
      <div className='flex column gap-lg' style={{maxWidth: '550px'}}>
        <div className={`text bold center size-xl ${colorClass}`}>{globalTexts.ourClientsHaveALotOfGoodStuffToSayAboutUs}</div>

        <div className={`text center size-lg ${colorClass}`}>{aAndLHoodsTexts.exhibit1AraisRestaurantLovedUs}</div>
        <EmbededInstagramLink instagramLink={instagramLinks.video.araisRestaurantSaysWeAre30PercentMoreAffortable} />

        <div className={`text center size-lg padding-top-xl ${colorClass}`}>{aAndLHoodsTexts.fromKitchenDesignToHoodInstallationWeBuiltButcherTheMarketNewRestaurant}</div>
        <EmbededInstagramLink instagramLink={instagramLinks.video.butcherTheMarketNewRestaurantInSanJuanCapistrano} />
      </div>
    </div>
  )
}

export default PortalInstaTestimonials;