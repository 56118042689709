import { globalTexts, helperGlobalTexts } from "../utils/internationalization";
import { clientStage, elementType, emojiAndDescription, icon, languageCode, languageCodeLabels, projectStatus } from "../data/constants";
import string from "../utils/string";

const commonMetadata = {
  name: {
    labelText: globalTexts.name,
    icon: icon.user,
  },
  type: {
    labelText: globalTexts.type,
    tipText: globalTexts.selectAnEmojiAsARepresentationOfTypeAndAsAMeansToCreateGroupsOrCategories,
    elementType: elementType.emoji,
    options: emojiAndDescription.map(emojiAndDescription => (
      {value: emojiAndDescription.emoji, label: emojiAndDescription.emoji}
    )).concat({value: '', label: '∅'}),
  },
  stage: {
    labelText: globalTexts.stage,
    elementType: elementType.select,
    icon: icon.heart,
    options: [
      {value: clientStage.lead, label: globalTexts[`clientStage-${clientStage.lead}`]},
      {value: clientStage.contacted, label: globalTexts[`clientStage-${clientStage.contacted}`]},
      {value: clientStage.engaged, label: globalTexts[`clientStage-${clientStage.engaged}`]},
      {value: clientStage.trial, label: globalTexts[`clientStage-${clientStage.trial}`]},
      {value: clientStage.interested, label: globalTexts[`clientStage-${clientStage.interested}`]},
      {value: clientStage.negotiating, label: globalTexts[`clientStage-${clientStage.negotiating}`]},
      {value: clientStage.customer, label: globalTexts[`clientStage-${clientStage.customer}`]},
      {value: clientStage.inactive, label: globalTexts[`clientStage-${clientStage.inactive}`]},
    ],
  },
  website: {
    labelText: globalTexts.website,
    icon: icon.web,
    formatterMethod: value => string.removeProtocolAndTrailingSlashes({value}),
  },
  company: {
    labelText: globalTexts.company,
    icon: icon.company,
  },
  email: {
    labelText: globalTexts.email,
    elementType: 'email',
    placeholderText: 'abcdefg007@gmail.com',
    icon: icon.email,
  },
  phone: {
    labelText: globalTexts.phone,
    elementType: elementType.tel,
    placeholderText: '+12003004567',
    icon: icon.phone,
    // Leave only numbers and plus sign
    formatterMethod: value => value.replace(/[^0-9+]/g, ''),
  },
  address: {
    labelText: globalTexts.address,
    icon: icon.location,
  },
  imageUrl: {
    labelText: globalTexts.imageUrl,
    icon: icon.image,
  },
  instagram: {
    labelText: globalTexts.instagram,
    icon: icon.instagram,
    formatterMethod: value => string.formatAsInstagramUrl({value}),
  },
  twitter: {
    labelText: globalTexts.twitter,
    icon: icon.twitter,
  },
  youtube: {
    labelText: globalTexts.youtube,
    icon: icon.youtube,
  },
  linkedin: {
    labelText: globalTexts.linkedin,
    icon: icon.linkedin,
  },
  facebook: {
    labelText: globalTexts.facebook,
    icon: icon.facebook,
  },
  notes: {
    labelText: globalTexts.notes,
    icon: icon.notes,
  },
  startDate: {
    labelText: globalTexts.startDate,
    elementType: elementType.date,
    icon: icon.calendar,
  },
  endDate: {
    labelText: globalTexts.endDate,
    elementType: elementType.date,
    icon: icon.calendarCheck,
  },
  description: {
    labelText: globalTexts.description,
    elementType: elementType.textarea,
    icon: icon.info,
    preformatted: true,
  },
  status: {
    labelText: globalTexts.status,
    elementType: elementType.select,
    icon: icon.tree,
    options: [
      {value: projectStatus.onHold, label: globalTexts.onHold},
      {value: projectStatus.finished, label: globalTexts.finished},
      {value: projectStatus.cancelled, label: globalTexts.cancelled},
      {value: projectStatus.exploring, label: globalTexts.exploring},
      {value: projectStatus.inProgress, label: globalTexts.inProgress},
      {value: projectStatus.negotiating, label: globalTexts.negotiating},
      {value: projectStatus.preparingToStart, label: globalTexts.preparingToStart},
    ],
  },
  preferredLanguage: {
    labelText: globalTexts.languageToSendMessages,
    elementType: elementType.select,
    options: [
      {value: languageCode.en, label: languageCodeLabels[languageCode.en]},
      {value: languageCode.es, label: languageCodeLabels[languageCode.es]},
      {value: languageCode.fr, label: languageCodeLabels[languageCode.fr]},
    ],
  },
};

const helperContactPropertiesMetadata = {
  email: commonMetadata.email,
  phone: commonMetadata.phone,
  address: commonMetadata.address,
  website: commonMetadata.website,
  twitter: commonMetadata.twitter,
  youtube: commonMetadata.youtube,
  linkedin: commonMetadata.linkedin,
  facebook: commonMetadata.facebook,
  instagram: commonMetadata.instagram,
}


// Referral Program
const referralProgram = {
  description: commonMetadata.description,
  targetAudience: {
    labelText: globalTexts.targetAudience,
    tipText: globalTexts.whatTypeOfPeopleWouldBeIncentivizedByTheRewardsInThisReferralProgram,
    icon: icon.people,
  },
  whatHelperStandsToWin: {
    labelText: globalTexts.whatHelperStandsToWin,
    tipText: globalTexts.listHereTheThingsAPersonWouldBeGiven,
    elementType: elementType.textarea,
    icon: icon.gift,
  },
  whatNewClientStandsToWin: {
    labelText: globalTexts.whatNewClientStandsToWin,
    tipText: globalTexts.listHereTheThingsANewClientWouldBeGiven,
    elementType: elementType.textarea,
    icon: icon.heart,
  },
};


// Project
const project = {
  name: {
    ...commonMetadata.name,
    tipText: globalTexts.projectNameOrPlaceWhereJobWasDone,
  },
  cost: {
    labelText: globalTexts.cost,
    icon: icon.moneyInvoice,
  },
  notes: commonMetadata.notes,
  address: commonMetadata.address,
  endDate: commonMetadata.endDate,
  imageUrl: commonMetadata.imageUrl,
  startDate: commonMetadata.startDate,
  description: {
    ...commonMetadata.description,
    tipText: globalTexts.whatTypeOfWorkWasDoneDuringThisProject,
  },
  status: commonMetadata.status,
};


// Client Person
const clientPerson = {
  name: commonMetadata.name,
  ...helperContactPropertiesMetadata,
  notes: commonMetadata.notes,
};


// Client V2
const clientv2 = {
  name: {
    ...commonMetadata.name,
    labelText: globalTexts.personOrCompanyToAddAsClient,
    tipText: globalTexts.egPaulRevereOrHopeHarborSolutionsInc,
  },
  type: commonMetadata.type,
  notes: commonMetadata.notes,
  imageUrl: commonMetadata.imageUrl,
  preferredLanguage: commonMetadata.preferredLanguage,
  ...helperContactPropertiesMetadata,
  stage: commonMetadata.stage,
  // ...helperContactProperties,
};


// Partner
const partner = {
  name: {
    ...commonMetadata.name,
    tipText: globalTexts.egPaulRevereOrHopeHarborSolutionsInc,
  },
  preferredLanguage: commonMetadata.preferredLanguage,
  portalUrl: {
    labelText: globalTexts.partnerPortalUrl,
    tipText: globalTexts.thisIsAUrlPartForReactRouter,
    icon: icon.web,
    formatterMethod: value => value.replace(/^https?:\/\//i, '').replace(/\/$/, ''),
  },
  ...helperContactPropertiesMetadata,
  imageUrl: commonMetadata.imageUrl,
  notes: commonMetadata.notes,
};

const entityMetadata = {
  partner,
  project,
  clientv2,
  clientPerson,
  referralProgram,
};

export default entityMetadata;