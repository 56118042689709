import Icon from "../Icon/Icon";
import AppBar from "../AppBar/AppBar";
import dataLayer from "../../data/dataLayer";
import ThumbEditProperty from "../Input/ThumbEditProperty";
import entityMetadata from "../../entities/entityMetadata";
import IconAndPropertyEditable from "../Global/IconAndPropertyEditable";
import { icon } from "../../data/constants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { globalTexts } from "../../utils/internationalization";

const saveProperty = ({clientPerson, setEditingPropertyName}) => {
  dataLayer.clientPerson.update({person: clientPerson})
  setEditingPropertyName(null);
};

const ClientPerson = () => {
  const navigate = useNavigate();
  const {partnerId, clientId, personId} = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [clientPerson, setClientPerson] = useState({});
  const [editingPropertyName, setEditingPropertyName] = useState(null);

  useEffect(() => {
    dataLayer.clientPerson.onGet({partnerId, clientId, personId, callback: ({document}) => setClientPerson(document)});
  }, []);

  return (
    <>
      <AppBar backUrl={`/clientv2/${partnerId}/${clientId}`} />

      <div className='padding-lg'>
        <div className='text size-xxl'>{clientPerson.name}</div>
        <div className='flex column gap-sm padding-top-xl'>
          <IconAndPropertyEditable propertyName='email' value={clientPerson.email} onClick={() => setEditingPropertyName('email')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='phone' value={clientPerson.phone} onClick={() => setEditingPropertyName('phone')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='address' value={clientPerson.address} onClick={() => setEditingPropertyName('address')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='notes' value={clientPerson.notes} onClick={() => setEditingPropertyName('notes')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='instagram' value={clientPerson.instagram} onClick={() => setEditingPropertyName('instagram')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='twitter' value={clientPerson.twitter} onClick={() => setEditingPropertyName('twitter')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='youtube' value={clientPerson.youtube} onClick={() => setEditingPropertyName('youtube')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='linkedin' value={clientPerson.linkedin} onClick={() => setEditingPropertyName('linkedin')} entityMetadata={entityMetadata.clientPerson} />
          <IconAndPropertyEditable propertyName='facebook' value={clientPerson.facebook} onClick={() => setEditingPropertyName('facebook')} entityMetadata={entityMetadata.clientPerson} />
        </div>

        <div className='padding-top-xxxl'>
          <div className='padding-md flex center pointer' onClick={() => setIsDeleting(true)}><Icon name={icon.delete} stroke='var(--palette-action)' /></div>
          {isDeleting && (
            <div className='padding-md action text size-lg center' onClick={async () => {await dataLayer.clientPerson.delete({partnerId, clientId, personId}); navigate(`/clientv2/${partnerId}/${clientId}`)}}>{globalTexts.confirmDelete}</div>
          )}
        </div>
      </div>

      <ThumbEditProperty propertyName={editingPropertyName} entity={clientPerson} setEntity={setClientPerson} onFinish={() => saveProperty({clientPerson, setEditingPropertyName})} finishButtonText={globalTexts.save} entityMetadata={entityMetadata.clientPerson} />
    </>
  );
};

export default ClientPerson;